import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import { Divider } from 'antd';

const UpdateTradePriceBySupplier = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation((params) => api.updateTradePriceUpdateBySupplier(params), {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to update price', 'error')
    }
  })

  useEffect(() => {
    setErrors([]);
    reset({
      id: data._id,
    });
  }, [data, modal])

  return (
    <Modal
      size="md"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      zIndex={9999}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Trade Price" : "Create Trade Price"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          <Row>
            <Col>
              <div className="mb-3">
                <label>Supplier</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("supplier_id", e?.value)
                  }}
                  options={data?.trade_prices?.map(item => ({
                    label: `${item.supplier.name} ($${item.price})`,
                    value: item.supplier._id,
                  }))}
                ></ReactSelect>
                {formErrors?.supplier && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.supplier && (
                  <span className="form-text text-danger">
                    {errors.supplier[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={handleSubmit(mutate)}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(UpdateTradePriceBySupplier);