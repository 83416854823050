import moment from 'moment';
import React, { useMemo } from 'react'
import { Table } from 'reactstrap';

const BuyPriceHistory = ({ data, updateField, refresh }) => {
  const scrapeBuyPrice = useMemo(
    () => data?.scrape_buy_price?.[data?.scrape_buy_price.length - 1],
    [data]
  )
  return (
    <>
      <h4
        className="text-center mb-3 text-white w-25 mx-auto p-2"
        style={{ backgroundColor: "#132747" }}
      >
        Buy Price Scrape History
      </h4>

      <Table className="table mb-5 table-bordered">
        <thead className="text-white" style={{ backgroundColor: "#132747" }}>
          <tr className="text-center">
            <th>Date</th>
            {scrapeBuyPrice?.scrape_urls?.map(scrapeUrl => (
              <th key={scrapeUrl._id}>{scrapeUrl.scrape_url_list.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            ...Array(
              scrapeBuyPrice?.scrape_urls?.[0]?.scrape_url_logs?.length
            ).keys(),
          ].map(log => (
            <tr className="text-center" key={log._id}>
              <th>
                {moment(
                  scrapeBuyPrice?.scrape_urls?.[0]?.scrape_url_logs?.[log]?.date
                ).format("DD/MM/YYYY")}
              </th>
              {[...Array(scrapeBuyPrice?.scrape_urls?.length).keys()].map(i =>
                scrapeBuyPrice?.scrape_urls?.[i]?.scrape_url_logs?.[log]
                  ?.price ? (
                  <th
                    key={i}
                  >{`$${scrapeBuyPrice?.scrape_urls?.[i]?.scrape_url_logs?.[log]?.price}`} (Exc)</th>
                ) : null
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default React.memo(BuyPriceHistory);