import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import moment from 'moment';
import UpdateTradePriceBySupplier from 'pages/Product/Action/UpdateTradePriceBySupplier';
import React, { useState } from 'react'
import { Table } from 'reactstrap';

const TradePriceImport = ({ data, updateField, refresh }) => {
  const [modalUpdateTradePrice, setModalUpdateTradePrice] = useState(false)
  const [modalData, setModalData] = useState(null)

  const {
    mutate: tradePriceUpdateCheapest,
    isLoading: tradePriceUpdateCheapestLoading,
  } = useMutation(params => api.updateTradePriceUpdateCheapest(params), {
    onSuccess: res => {
      showToast("Successfully update product from cheapest price")
      if (!res.status) {
        showToast("Failed to update product from cheapest price", "error")
      }
      refresh()
    },
    onError: err => {
      console.log(err)
      showToast(
        err.response.data ? err.message : "Failed to import price",
        "error"
      )
    },
  })

  const tradePriceMin = data?.trade_prices?.reduce(
    (prev, curr) => (prev.price < curr.price ? prev : curr),
    0
  )

  const toggleUpdateTradePrice = () => {
    if (modalUpdateTradePrice) {
      setModalUpdateTradePrice(false)
    } else {
      setModalUpdateTradePrice(true)
    }
  }

  const handleUpdateTradePriceModal = () => {
    setModalData(data)
    toggleUpdateTradePrice()
  }

  return (
    <>
      {data?.trade_prices.length > 0 ? (
        <div className="my-5">
          <h4
            className="text-center mb-3 text-white w-25 mx-auto p-2"
            style={{ backgroundColor: "#132747" }}
          >
            Trade Price
          </h4>
          <Table className="table table-bordered">
            <thead
              className="text-white"
              style={{ backgroundColor: "#132747" }}
            >
              <tr className="text-center">
                {data?.trade_prices?.map(tradePrice => (
                  <th key={tradePrice._id}>{tradePrice.supplier.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                {data?.trade_prices?.map(tradePrice => (
                  <th
                    key={tradePrice._id}
                    className={
                      tradePriceMin.price == tradePrice.price
                        ? "text-success"
                        : null
                    }
                  >
                    ${Number(tradePrice.price).toFixed(2)} (Exc)
                  </th>
                ))}
              </tr>
              <tr className="text-center">
                {data?.trade_prices?.map(tradePrice => (
                  <th key={tradePrice._id}>
                    {moment(tradePrice.created_at).format("DD/MM/YYYY")} by{" "}
                    {tradePrice?.user?.name ?? "-"}
                  </th>
                ))}
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={() => tradePriceUpdateCheapest({ id: data?._id })}
              >
                Update by Cheapest
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={handleUpdateTradePriceModal}
              >
                Update by Selected Supplier
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {modalUpdateTradePrice && (
        <UpdateTradePriceBySupplier
          modal={modalUpdateTradePrice}
          toggle={toggleUpdateTradePrice}
          handleCreateModal={handleUpdateTradePriceModal}
          data={modalData}
          refresh={() => {}}
        />
      )}
    </>
  )
}

export default React.memo(TradePriceImport);