import React, { useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row, Spinner, UncontrolledButtonDropdown, UncontrolledDropdown } from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import CreateUpdate from './Action/CreateUpdate';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { getImageUrl, getProductStatus, showConfirm, showToast } from 'helpers/utils';
import { del } from 'helpers/api_helper';
import DeleteConfirmation from 'components/Common/DeleteConfirmation';
import moment from 'moment';
import ReactSelect from 'react-select';

const ScrapePrice = () => {
  const [deleteId, setDeleteId] = useState(null)
  const [modal, setModal] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [show, setShow] = useState(10)
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedSupplier, setSelectedSupplier] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [showDelete, setShowDelete] = useState(false)

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);

  const {
    data,
    refetch: getData,
    isLoading,
  } = useQuery(
    [
      "product-price",
      search,
      page,
      show,
      selectedBrand,
      selectedSupplier,
      selectedCategory,
    ],
    () =>
      api.scrapePrice({
        params: {
          page: page,
          limit: show,
          search: search,
          brand: selectedBrand,
          supplier: selectedSupplier,
          category: selectedCategory,
        },
      }),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: suppliers } = useQuery(
    ['price-suppliers'],
    () => api.scrapePriceSupplier(),
    {
      refetchOnWindowFocus: false,
    });

  const { data: brands } = useQuery(
  ['brand-lists'],
  () => api.brandList(),
  {
    refetchOnWindowFocus: false,
  });

  const { data: categories } = useQuery(
    ["categories"],
    () => api.categorySelect(),
    {
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: startScrape, isLoading: scrapeLoading } = useMutation((params) => api.startScrapePrice({
    id: isCheck,
    ...params
  }), {
    onSuccess: () => {
      showToast('Successfully syncronize')
      getData()
      return;
    },
    onError: (err) => {
      showToast('Failed to scrape price', 'error')
    }
  })

  const { mutate: priceUpdate, isLoading: priceUpdateLoading } = useMutation((params) => api.sendPriceUpdate({ id: isCheck, ...params }), {
    onSuccess: (res) => {
      showToast('Successfully update price')
      if (!res.status) {
        showToast('Failed to update price', 'error')
      }
      getData();
    },
    onError: (err) => {
      showToast('Failed to update price', 'error')
    }
  })

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    if (isCheckAll) {
      setIsCheck([]);
    }else{
      setIsCheck(data.data.map(li => li._id));
    }
  };

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const handleCreateModal = () => {
    setModalData(null)
    toggle();
  };

  const handleUpdateModal = (data) => {
    setModalData(data)
    toggle();
  };

  const deleteAction = async () => {
    const products = await del(`/products/${deleteId}`);
    setShowDelete(false)
    getData();
  }

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: false,
    hideSelectAll: false,
    onSelectAll: (isSelect, rows, e) => {
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setIsCheck(prev => [...prev, row._id])
      }else{
        setIsCheck(prev => prev.filter(item => item != row._id))
      }
    },
    selected: isCheck,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: show,
    pageStartIndex: 1,
    totalSize: data?.total,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "sku",
      sort: "desc",
      order: 'desc',
    },
  ];

  const ProductColumns = toggleModal => [
    {
      dataField: "_id",
      hidden: true,
      // headerFormatter: () => {
      //   return (
      //     <Checkbox
      //       name="selectAll"
      //       id="selectAll"
      //       handleClick={handleSelectAll}
      //       isChecked={isCheckAll}
      //     />
      //   )
      // },
      // formatter: (cellContent, row, i) => {
      //   return (
      //     <Checkbox
      //       name={row.sku}
      //       id={row._id}
      //       handleClick={handleClick}
      //       isChecked={isCheck.includes(row._id)}
      //     />
      //     // <Checkbox checked={checkedRows.includes(row._id)} onChange={() => console.log('first')}></Checkbox>
      //   )
      // }
    },
    {
      dataField: "sku",
      text: "SKU",
      formatter: (cellContent, row, i) => (
        <a
          href="#"
          className={!row?.is_active ? "text-danger" : null}
          onClick={e => {
            e.preventDefault()
            handleUpdateModal(row)
          }}
        >
          {row.sku}
        </a>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      maxWidth: 200,
      formatter: (cellContent, row, i) => (
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            handleUpdateModal(row)
          }}
        >
          {row.name}
        </a>
      ),
      style: (colum, colIndex) => {
        return { width: "300px", textWrap: "wrap" }
      },
    },
    {
      dataField: "brand",
      text: "Brand",
      formatter: (cellContent, row, i) => {
        let brandElement = null
        if (row.in_stock == 1) {
          brandElement = (
            <small className="text-success">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        } else if (row.in_stock == 4 || row.in_stock == 2) {
          brandElement = (
            <small className="text-warning">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        } else if (row.in_stock == 0 || row.in_stock == 3) {
          brandElement = (
            <small className="text-danger">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        }
        return (
          <>
            <div>{row.brand}</div>
            {brandElement}
            {row.in_stock == 3 ? (
              <small className="text-warning d-block">Alternative</small>
            ) : null}
          </>
        )
      },
    },
    {
      dataField: "suppliers",
      text: "Suppliers",
      formatter: (cellContent, row, i) =>
        [...new Map(row.suppliers.map(item => [item, item])).values()]
          .map(item => item)
          .join(", "),
    },
    {
      dataField: "price",
      text: "Price (Include GST)",
      formatter: (cellContent, row, i) => {
        let scrape = row.scrape[row.scrape.length - 1]
        const checkPriceLowest = scrape?.scrape_urls
          .filter(price => price != null)
          .every(item => Number(item.price) > Number(row.price))
        return (
          <>
            <div>
              <b>Buy Price: {`$${Number(row.buy_price).toFixed(2)}`}</b>
            </div>
            {checkPriceLowest ? (
              <div>
                <b className="text-success">
                  Price: {`$${Number(row.price).toFixed(2)}`}
                </b>
              </div>
            ) : (
              <div>
                <b className="text-danger">
                  Price: {`$${Number(row.price).toFixed(2)}`}
                </b>
              </div>
            )}
            {/* <div><b>RRP Price: {`$${Number(row.price).toFixed(2)}`}</b></div> */}
            <div>
              <b>Trade Price: {`$${Number(row.price_1).toFixed(2)}`}</b>
            </div>
            <div>
              <b>
                Limit Price: {`$${Number(scrape?.limit_price ?? 0).toFixed(2)}`}
              </b>
            </div>
          </>
        )
      },
    },
    {
      dataField: "scrape",
      text: "Scrape (Include GST)",
      formatter: (cellContent, row, i) => {
        let scrape = row.scrape[row.scrape.length - 1]
        var findMinPrice = Math.min.apply(
          null,
          scrape?.scrape_urls
            ?.filter(item => item.price)
            .map(item => (item.price ? Number(item.price) : null))
        )

        return (
          <div>
            {scrape?.scrape_urls?.map(item => (
              <a
                href={item.url}
                target="_blank"
                rel="noreferrer"
                style={{
                  display: "block",
                  fontWeight:
                    !item.status || findMinPrice == item.price
                      ? "bold"
                      : "unset",
                }}
                key={item._id}
                className={`${!item.status ? "text-danger" : ""} ${
                  findMinPrice == item.price && "text-success"
                }`}
              >
                {item.scrape_url_list?.name} (
                {item.price ? `$${item.price}` : "-"}){" "}
                {!item.status ? "(X)" : ""}
              </a>
            ))}
          </div>
        )
      },
    },
    {
      dataField: "updated_at",
      text: "Last Updated",
      formatter: (cellContent, row, i) => {
        let scrape = row.scrape[row.scrape.length - 1]
        return moment(scrape?.updated_at).format("DD MMM YYYY hh:mm")
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={() => handleUpdateModal(row)}>
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDeleteId(row._id)
                  setShowDelete(true)
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ]
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Scrape RRP Price" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="border-bottom">
                  <div className="d-flex align-items-center">
                    <h5 className="mb-0 card-title flex-grow-1">
                      Scrape Price Lists
                    </h5>
                    <div className="flex-shrink-0">
                      {/* <Link to="#!" className="btn btn-light me-1">Sync & Update <i className="mdi mdi-refresh"></i></Link> */}

                      {/* <UncontrolledDropdown className="dropdown d-inline-block me-1" disabled={priceUpdateLoading}>
                        <DropdownToggle type="menu" className="btn btn-light" id="dropdownMenuButton1">
                          {isCheck.length > 0 ? 'Update Selected Price' : 'Update All Price'} to {priceUpdateLoading ? (<i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>): (<i className = "mdi mdi-dots-vertical"></i>)}</DropdownToggle>
                        <DropdownMenu>
                          <li><DropdownItem onClick={priceUpdate}>Alarm Expert</DropdownItem></li>
                          <li><DropdownItem href="#">Surveillance Master</DropdownItem></li>
                        </DropdownMenu>
                      </UncontrolledDropdown> */}

                      {/* {priceUpdateLoading ? (
                        <button className="btn btn-light me-1">
                          Loading <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        </button>
                      ) : (
                        <button className="btn btn-light me-1" onClick={priceUpdate}>
                          {isCheck.length > 0 ? 'Update Selected Price' : 'Update All Price'} <i className="mdi mdi-refresh"></i>
                        </button>
                      )} */}

                      <UncontrolledDropdown
                        className="dropdown d-inline-block me-1"
                        disabled={priceUpdateLoading}
                      >
                        <DropdownToggle
                          type="menu"
                          className="btn btn-light"
                          id="dropdownMenuButton1"
                        >
                          {isCheck.length > 0
                            ? "Update Selected Price"
                            : "Update All Price"}{" "}
                          {priceUpdateLoading ? (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                          ) : (
                            <i className="mdi mdi-dots-vertical"></i>
                          )}
                        </DropdownToggle>
                        <DropdownMenu>
                          <li>
                            <DropdownItem
                              onClick={() => showConfirm(() => priceUpdate())}
                            >
                              {isCheck.length > 0
                                ? "Update Selected Price"
                                : "Update All Price"}
                            </DropdownItem>
                          </li>
                          {(selectedBrand ||
                            selectedSupplier ||
                            selectedCategory) &&
                          !isCheck.length ? (
                            <li>
                              <DropdownItem
                                onClick={() =>
                                  showConfirm(() =>
                                    priceUpdate({
                                      brand: selectedBrand,
                                      supplier: selectedSupplier,
                                      category: selectedCategory,
                                    })
                                  )
                                }
                              >
                                Update Price by Filter
                              </DropdownItem>
                            </li>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                      <UncontrolledDropdown
                        className="dropdown d-inline-block me-1"
                        disabled={scrapeLoading || data?.is_running == "true"}
                      >
                        <DropdownToggle
                          type="menu"
                          className={`btn ${
                            data?.is_running == "true"
                              ? "btn-danger"
                              : "btn-light"
                          }`}
                          id="dropdownMenuButton1"
                        >
                          {isCheck.length > 0
                            ? "Scrape Selected"
                            : "Scrape All"}{" "}
                          {scrapeLoading ? (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                          ) : (
                            <i className="mdi mdi-dots-vertical"></i>
                          )}
                        </DropdownToggle>
                        <DropdownMenu>
                          <li>
                            <DropdownItem
                              onClick={() => showConfirm(() => startScrape())}
                            >
                              {isCheck.length > 0
                                ? "Scrape Selected"
                                : "Scrape All"}
                            </DropdownItem>
                          </li>
                          {(selectedBrand || selectedSupplier) &&
                          !isCheck.length ? (
                            <li>
                              <DropdownItem
                                onClick={() =>
                                  showConfirm(() =>
                                    startScrape({
                                      brand: selectedBrand,
                                      supplier: selectedSupplier,
                                    })
                                  )
                                }
                              >
                                Scrape All by Filter
                              </DropdownItem>
                            </li>
                          ) : null}
                        </DropdownMenu>
                      </UncontrolledDropdown>

                      {/* {syncActive ? (
                        <button className="btn btn-light me-1">
                          Loading <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                        </button>
                      ) : (
                        <button className="btn btn-light me-1" onClick={() => {
                          setSyncActive(true)
                          startScrape()
                        }}>
                          {isCheck.length > 0 ? 'Scrape Selected' : 'Scrape All'} <i className="mdi mdi-refresh"></i>
                        </button>
                      )} */}
                    </div>
                  </div>
                </CardBody>
                <CardBody>
                  <Row className="mb-2">
                    <Col sm="2">
                      <div className="search-box me-2 mb-2 d-inline-block">
                        <div className="position-relative">
                          <input
                            placeholder="Search..."
                            className="form-control"
                            onInput={e => {
                              setSearch(e.target.value)
                            }}
                            value={search}
                            type="text"
                          />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                    <Col sm="1">
                      <div className="w-100">
                        <div className="position-relative">
                          <select
                            className="form-control text-center px-0"
                            onChange={e => setShow(e.target.value)}
                            value={show}
                          >
                            <option value="10">Show 10</option>
                            <option value="20">Show 20</option>
                            <option value="50">Show 50</option>
                            <option value="100">Show 100</option>
                          </select>
                        </div>
                      </div>
                    </Col>
                    <Col sm="9">
                      <Row>
                        <Col>
                          <ReactSelect
                            options={categories?.categories}
                            onChange={e => {
                              setSelectedCategory(e?.value)
                            }}
                            placeholder="Select Category"
                            isClearable
                          ></ReactSelect>
                        </Col>
                        <Col>
                          <ReactSelect
                            options={brands?.brands}
                            onChange={e => {
                              setSelectedBrand(e?.value)
                            }}
                            placeholder="Select Brand"
                            isClearable
                          ></ReactSelect>
                        </Col>
                        <Col>
                          <ReactSelect
                            options={suppliers?.suppliers}
                            onChange={e => {
                              setSelectedSupplier(e?.value)
                            }}
                            placeholder="Select Competitor"
                            isClearable
                          ></ReactSelect>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <FormGroup check inline>
                      <input
                        id="selectAll"
                        name="selectAll"
                        onClick={handleSelectAll}
                        checked={isCheckAll}
                        type="checkbox"
                      />{" "}
                      <Label check>Select All</Label>
                    </FormGroup>
                  </Row>
                  {isLoading ? (
                    <Spinner className="ms-2 spinner-loading" color="success" />
                  ) : (
                    <Row>
                      <Col sm="12">
                        {data && (
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="_id"
                            columns={ProductColumns()}
                            data={data.data}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="_id"
                                data={data.data}
                                columns={ProductColumns()}
                                bootstrap4
                                // search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="_id"
                                            responsive
                                            sorted={false}
                                            striped={false}
                                            defaultSorted={defaultSorted}
                                            selectRow={selectRow}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            clickToSelect
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <Pagination
                                          changePage={e => setPage(e.page)}
                                          data={data}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                        )}
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal && (
        <CreateUpdate
          modal={modal}
          toggle={toggle}
          handleCreateModal={handleCreateModal}
          data={modalData}
          refresh={getData}
        />
      )}
      <DeleteConfirmation
        showDelete={showDelete}
        setShowDelete={() => setShowDelete(false)}
        deleteAction={deleteAction}
      />
    </React.Fragment>
  )
}

export default React.memo(ScrapePrice);