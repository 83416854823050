import { GET_MODAL_DATA, OPEN_MODAL, SET_MODAL_DATA } from "./actionTypes"

export const getModalDataset = () => ({
  type: GET_MODAL_DATA,
})
export const setModalDataset = (payload) => ({
  type: SET_MODAL_DATA,
  payload: payload,
})
export const setOpenModal = (payload) => ({
  type: OPEN_MODAL,
  payload: payload,
})
