import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showConfirm, showToast } from 'helpers/utils';
import moment from 'moment';
import UpdateBuyPriceBySupplier from 'pages/Product/Action/UpdateBuyPriceBySupplier';
import UpdateSupplierBuyPrice from 'pages/Product/Action/UpdateSupplierBuyPrice';
import React, { useState } from 'react'
import { Table } from 'reactstrap';

const BuyPriceImport = ({ data, updateField, refresh }) => {
  const [modalData, setModalData] = useState(null)
  const [modalUpdateBuyPrice, setModalUpdateBuyPrice] = useState(false)
  const [modalUpdate, setModalUpdate] = useState(false)

  const {
    mutate: buyPriceUpdateCheapest,
    isLoading: buyPriceUpdateCheapestLoading,
  } = useMutation(params => api.updateBuyPriceUpdateCheapest(params), {
    onSuccess: res => {
      showToast("Successfully update product from cheapest price")
      if (!res.status) {
        showToast("Failed to update product from cheapest price", "error")
      }
      refresh()
    },
    onError: err => {
      console.log(err)
      showToast(
        err.response.data ? err.response.data.message : "Failed to import price",
        "error"
      )
    },
  })

  const {
    mutate: deleteBuyPriceImport,
    isLoading: deleteBuyPriceImportLoading,
  } = useMutation(params => api.deleteBuyPriceImport(params), {
    onSuccess: res => {
      showToast("Successfully delete price")
      if (!res.status) {
        showToast("Failed to delete price", "error")
      }
      refresh()
    },
    onError: err => {
      console.log(err)
      showToast(
        err.response.data ? err.message : "Failed to delete price",
        "error"
      )
    },
  })

  const buyPriceMin = data?.buy_prices?.reduce(
    (prev, curr) => (prev.price < curr.price ? prev : curr),
    0
  )

  const toggleUpdateBuyPrice = () => {
    if (modalUpdateBuyPrice) {
      setModalUpdateBuyPrice(false)
    } else {
      setModalUpdateBuyPrice(true)
    }
  }

  const handleUpdateBuyPriceModal = () => {
    setModalData(data)
    toggleUpdateBuyPrice()
  }

  const toggleUpdate = () => {
    if (modalUpdate) {
      setModalUpdate(false)
    } else {
      setModalUpdate(true)
    }
  }

  const handleUpdateModal = (value) => {
    setModalData(value)
    toggleUpdate()
  }

  return (
    <>
      {data?.buy_prices.length > 0 ? (
        <div className="my-5">
          <h4
            className="text-center mb-3 text-white w-25 mx-auto p-2"
            style={{ backgroundColor: "#132747" }}
          >
            Buying Price
          </h4>
          <Table className="table table-bordered">
            <thead
              className="text-white"
              style={{ backgroundColor: "#132747" }}
            >
              <tr className="text-center">
                {data?.buy_prices?.map(buyPrice => (
                  <th key={buyPrice._id}>{buyPrice.supplier.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                {data?.buy_prices?.map(buyPrice => (
                  <th
                    key={buyPrice._id}
                    className={
                      buyPriceMin.price == buyPrice.price
                        ? "text-success position-relative"
                        : "position-relative"
                    }
                  >
                    <a href="#" onClick={() => handleUpdateModal(buyPrice)}>
                      ${Number(buyPrice.price).toFixed(2)} (Exc){" "}
                      <i className="bx bx-edit-alt"></i>
                    </a>
                    <a
                      href="#"
                      onClick={() =>
                        showConfirm(() =>
                          deleteBuyPriceImport(buyPrice._id)
                        )
                      }
                      className="position-absolute"
                      style={{ right: 10 }}
                    >
                      <i
                        className="bx bx-trash-alt"
                        style={{ fontSize: 18 }}
                      ></i>
                    </a>
                  </th>
                ))}
              </tr>
              <tr className="text-center">
                {data?.buy_prices?.map(buyPrice => (
                  <th key={buyPrice._id}>
                    {moment(buyPrice.created_at).format("DD/MM/YYYY")} by{" "}
                    {buyPrice?.user?.name ?? "-"}
                  </th>
                ))}
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={() => buyPriceUpdateCheapest({ id: data?._id })}
              >
                Update by Cheapest
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={handleUpdateBuyPriceModal}
              >
                Update by Selected Supplier
              </button>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={() =>
                  handleUpdateModal({
                    product_id: data?._id,
                    buy_prices: data?.buy_prices,
                  })
                }
              >
                Create New Buy Price
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {modalUpdateBuyPrice && (
        <UpdateBuyPriceBySupplier
          modal={modalUpdateBuyPrice}
          toggle={toggleUpdateBuyPrice}
          handleCreateModal={handleUpdateBuyPriceModal}
          data={modalData}
          refresh={refresh}
        />
      )}
      {modalUpdate && (
        <UpdateSupplierBuyPrice
          modal={modalUpdate}
          toggle={toggleUpdate}
          handleCreateModal={handleUpdateModal}
          data={modalData}
          refresh={refresh}
        />
      )}
    </>
  )
}

export default React.memo(BuyPriceImport);