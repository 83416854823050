import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const { id } = useParams();
  const [errors, setErrors] = useState([])
  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation(
    async params =>
      data
        ? api.updatePriceLevel(data._id, params)
        : api.createPriceLevel(params),
    {
      onSuccess: res => {
        console.log(res)
        showToast(res.message, !res.status && "error")
        if (!res.status) {
          setErrors(res.errors)
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to submit brand", "error")
      },
    }
  )

  const { data: brands, isLoading: loadingBrand } = useQuery(
    ["brand-select"],
    () => api.brandSelect(),
    {
      refetchOnWindowFocus: true,
    }
  )

  const { data: categories, isLoading: loadingCategory } = useQuery(
    ["category-select"],
    () => api.categorySelect(),
    {
      refetchOnWindowFocus: true,
    }
  )

  useEffect(() => {
    setErrors([]);
    reset({
      ...data,
    });
  }, [data, modal])

  return (
    <Modal size="lg" toggle={() => handleCreateModal()} isOpen={modal} centered>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Price Level" : "Create Price Level"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          <Row>
            <Col>
              <div className="mb-3">
                <label>Brand</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("brand", e.value)
                  }}
                  options={brands?.brands}
                  isLoading={loadingBrand}
                  defaultValue={{
                    label: data?.brand,
                    value: data?.brand,
                  }}
                ></ReactSelect>
                {formErrors?.brand && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.brand && (
                  <span className="form-text text-danger">
                    {errors.brand[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Category</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("category", e.value)
                  }}
                  options={categories?.categories}
                  isLoading={loadingCategory}
                  defaultValue={{
                    label: data?.category,
                    value: data?.category,
                  }}
                ></ReactSelect>
                {formErrors?.category && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.category && (
                  <span className="form-text text-danger">
                    {errors.category[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>RRP Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="RRP Price"
                  {...register("price", { required: true })}
                />
                {formErrors?.price && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price && (
                  <span className="form-text text-danger">
                    {errors.price[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Trade Price (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Trade Price"
                  {...register("price_1", { required: true })}
                />
                {formErrors?.price_1 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_1 && (
                  <span className="form-text text-danger">
                    {errors.price_1[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Silver (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Silver"
                  {...register("price_2", { required: true })}
                />
                {formErrors?.price_2 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_2 && (
                  <span className="form-text text-danger">
                    {errors.price_2[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Gold (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Gold"
                  {...register("price_3", { required: true })}
                />
                {formErrors?.price_3 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_3 && (
                  <span className="form-text text-danger">
                    {errors.price_3[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Platinum (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Platinum"
                  {...register("price_4", { required: true })}
                />
                {formErrors?.price_4 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_4 && (
                  <span className="form-text text-danger">
                    {errors.price_4[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Diamond (%)</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Diamond"
                  {...register("price_5", { required: true })}
                />
                {formErrors?.price_5 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_5 && (
                  <span className="form-text text-danger">
                    {errors.price_5[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={handleSubmit(mutate)}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);