import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import HomepageSetting from "pages/HomepageSetting"
import Setting from "pages/Setting"
import Category from "pages/Category"
import Brand from "pages/Brand"
import User from "pages/User"
import Footer from "pages/Setting/Footer"
import Product from "pages/Product"
import ScrapeTrade from "pages/ScrapeTrade"
import ScrapeBuyPrice from "pages/ScrapeBuyPrice"
import ScrapePrice from "pages/ScrapePrice"
import SupplierBuyPrice from "pages/SupplierBuyPrice"
import SupplierTradePrice from "pages/SupplierTradePrice"
import SupplierPrice from "pages/SupplierPrice"
import Supplier from "pages/Supplier"
import PriceLevel from "pages/PriceLevel"
import StockUpdate from "pages/StockUpdate"

const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },

  // //profile
  { path: "/profile", component: UserProfile },

  { path: "/categories", component: Category },
  { path: "/categories/:id", component: Category },

  { path: "/brands", component: Brand },

  { path: "/homepage-setting", component: HomepageSetting },
  { path: "/settings", component: Setting },

  { path: "/scrape-prices", component: ScrapePrice },
  { path: "/scrape-trades", component: ScrapeTrade },
  { path: "/scrape-buy-price", component: ScrapeBuyPrice },

  { path: "/suppliers", component: Supplier },
  { path: "/supplier-price", component: SupplierPrice },
  { path: "/supplier-buy-price", component: SupplierBuyPrice },
  { path: "/supplier-trade-price", component: SupplierTradePrice },

  { path: "/price-level", component: PriceLevel },
  { path: "/stock-update", component: StockUpdate },

  { path: "/products", component: Product },
  { path: "/users", component: User },

  { path: "/settings/footer", component: Footer },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
]

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { authProtectedRoutes, publicRoutes }
