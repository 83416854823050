import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import moment from 'moment';
import UpdatePriceBySupplier from 'pages/Product/Action/UpdatePriceBySupplier';
import React, { useState } from 'react'
import { Table } from 'reactstrap';

const PriceImport = ({ data, updateField, refresh }) => {
  const [modalData, setModalData] = useState(null)
  const [modalUpdatePrice, setModalUpdatePrice] = useState(false)

  const { mutate: priceUpdateCheapest, isLoading: priceUpdateCheapestLoading } =
    useMutation(params => api.updatePriceUpdateCheapest(params), {
      onSuccess: res => {
        showToast("Successfully update product from cheapest price")
        if (!res.status) {
          showToast("Failed to update product from cheapest price", "error")
        }
        refresh()
      },
      onError: err => {
        console.log(err)
        showToast(
          err.response.data ? err.message : "Failed to import price",
          "error"
        )
      },
    })

  const priceMin = data?.prices?.reduce(
    (prev, curr) => (prev.price < curr.price ? prev : curr),
    0
  )

  const toggleUpdatePrice = () => {
    if (modalUpdatePrice) {
      setModalUpdatePrice(false)
    } else {
      setModalUpdatePrice(true)
    }
  }

  const handleUpdatePriceModal = () => {
    setModalData(data)
    toggleUpdatePrice()
  }

  return (
    <>
      {data?.prices.length > 0 ? (
        <div className="my-5">
          <h4
            className="text-center mb-3 text-white w-25 mx-auto p-2"
            style={{ backgroundColor: "#132747" }}
          >
            RRP
          </h4>
          <Table className="table table-bordered">
            <thead
              className="text-white"
              style={{ backgroundColor: "#132747" }}
            >
              <tr className="text-center">
                {data?.prices?.map(price => (
                  <th key={price._id}>{price.supplier.name}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                {data?.prices?.map(price => (
                  <th
                    key={price._id}
                    className={
                      priceMin.price == price.price ? "text-success" : null
                    }
                  >
                    ${Number(price.price).toFixed(2)} (Inc)
                  </th>
                ))}
              </tr>
              <tr className="text-center">
                {data?.prices?.map(price => (
                  <th key={price._id}>
                    {moment(price.created_at).format("DD/MM/YYYY")} by{" "}
                    {price?.user?.name ?? "-"}
                  </th>
                ))}
              </tr>
            </tbody>
          </Table>
          <div className="row">
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={() => priceUpdateCheapest({ id: data?._id })}
              >
                Update by Cheapest
              </button>
            </div>
            <div className="col">
              <button
                className="btn btn-primary w-100"
                onClick={handleUpdatePriceModal}
              >
                Update by Selected Supplier
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {modalUpdatePrice && (
        <UpdatePriceBySupplier
          modal={modalUpdatePrice}
          toggle={toggleUpdatePrice}
          handleCreateModal={handleUpdatePriceModal}
          data={modalData}
          refresh={() => {}}
        />
      )}
    </>
  )
}

export default React.memo(PriceImport);