import { titleCase } from 'helpers/utils';
import moment from 'moment';
import React, { useMemo } from 'react'
import { Table } from 'reactstrap';

const Stock = ({ data, updateField, refresh }) => {
  const scrapeTradeStock = useMemo(() => {
    let scrape = data?.scrape_trade?.find(item => item.trade_type == 1)
    let result = scrape?.scrape_urls?.filter(
      item =>
        !!item.stock &&
        item.scrape_url_logs?.filter(log => !!log.stock && log.stock != "{}")
          .length > 0
    )

    return result
  }, [data])

  return (
    <>
      {scrapeTradeStock?.map(item => (
        <>
          <h4
            className="text-center mb-3 bg-primary text-white py-2"
            key={item._id}
          >
            Stock {titleCase(item.scrape_url_list.name)}
          </h4>

          <Table className="table mb-5 table-bordered">
            <thead
              className="text-white"
              style={{ backgroundColor: "#132747" }}
            >
              <tr className="text-center">
                <th>Date</th>
                {/* {item?.scrape_url_logs?.map(log => (
                            log?.stock ? Object.keys(JSON.parse(log?.stock)).map(stock => (
                              <th key={stock}>{stock}</th>
                            )) : <></>
                        ))} */}
                {Object.keys(JSON.parse(item.stock)).map(stock => (
                  <th key={stock}>{stock}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {item?.scrape_url_logs?.map(log => (
                <tr className="text-center" key={log._id}>
                  <th>
                    {log?.date
                      ? moment(log?.date ?? null).format("DD/MM/YYYY")
                      : "-"}
                  </th>
                  {log?.stock ? (
                    Object.keys(JSON.parse(log?.stock)).map(stock => (
                      <th
                        key={stock}
                        className={
                          JSON.parse(log.stock)[stock] == "Out of Stock"
                            ? "text-danger"
                            : JSON.parse(log.stock)[stock] == "Low Stock"
                            ? "text-warning"
                            : "text-success"
                        }
                      >
                        {JSON.parse(log.stock)[stock]}
                      </th>
                    ))
                  ) : (
                    <></>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ))}
    </>
  )
}

export default React.memo(Stock);