import moment from 'moment';
import React, { useMemo } from 'react'
import { Table } from 'reactstrap';

const TradePriceHistory = ({ data, updateField, refresh }) => {
  const scrapeTrade = useMemo(
    () => data?.scrape_trade?.find(item => item.trade_type == 1),
    [data]
  )

  return (
    <>
      <h4
        className="text-center mt-5 mb-3 text-white w-25 mx-auto p-2"
        style={{ backgroundColor: "#132747" }}
      >
        Trade Price Scrape History
      </h4>

      <Table className="table mb-5 table-bordered">
        <thead className="text-white" style={{ backgroundColor: "#132747" }}>
          <tr className="text-center">
            <th>Date</th>
            {scrapeTrade?.scrape_urls?.map(scrapeUrl => (
              <th key={scrapeUrl._id}>{scrapeUrl.scrape_url_list.name}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[
            ...Array(
              scrapeTrade?.scrape_urls?.[0]?.scrape_url_logs?.length
            ).keys(),
          ].map(log => (
            <tr className="text-center" key={log._id}>
              <th>
                {moment(
                  scrapeTrade?.scrape_urls?.[0]?.scrape_url_logs?.[log]?.date
                ).format("DD/MM/YYYY")}
              </th>
              {[...Array(scrapeTrade?.scrape_urls?.length).keys()].map(i =>
                scrapeTrade?.scrape_urls?.[i]?.scrape_url_logs?.[log]?.price ? (
                  <th key={i}>
                    {`$${scrapeTrade?.scrape_urls?.[i]?.scrape_url_logs?.[log]?.price}`}{" "}
                    (Exc)
                  </th>
                ) : null
              )}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default React.memo(TradePriceHistory);