import React, { useCallback, useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { getProductStatus, showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import { Divider } from 'antd';

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const [searchProduct, setSearchProduct] = useState("")
  const { register, setValue, handleSubmit, watch, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation(async (params) => {
    params.buy_price = params.buy_price * 1.1;
    params.price = params.price * 1.1;
    params.price_1 = params.price_1 * 1.1;
    params.price_2 = params.price_2 * 1.1;
    params.price_3 = params.price_3 * 1.1;
    params.price_4 = params.price_4 * 1.1;
    params.price_5 = params.price_5 * 1.1;
    const formData = new FormData();
    for (const [key, value] of Object.entries(params)) {
      if (typeof value !== 'undefined' && value !== null) {
        if (value instanceof FileList) {
          formData.append(key, value[0])
        } else {
          formData.append(key, value)
        }
      }
    }
    if (data) {
      formData.append('_method', 'put')
    }
    return (await data) ? api.updateProduct(data._id, formData) : api.createProduct(formData);
  }, {
    onSuccess: (res) => {
      console.log(res)
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit product', 'error')
    }
  })

  const { data: products, isLoading: loadingProduct } = useQuery(
    ["product-select", searchProduct],
    () => api.productSelect({ params: { search: searchProduct } }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { data: brands, isLoading: loadingBrand } = useQuery(
    ['brands'],
    () => api.brandSelect(),
    {
      refetchOnWindowFocus: true,
    });

    const { data: suppliers, isLoading: loadingSupplier } = useQuery(
    ['suppliers'],
      () => api.supplierSelect(),
    {
      refetchOnWindowFocus: true,
    });

  useEffect(() => {
    setErrors([]);
    if (data) {
      reset({
        // ...data,
        sku: data.sku,
        name: data.name,
        brand: data.brand,
        suppliers: data.suppliers,
        in_stock: data.in_stock,
        alternative_product: data.alternative_product,
        buy_price: data.buy_price / 1.1,
        price: (data.price / 1.1).toFixed(2),
        price_1: (data.price_1 / 1.1).toFixed(2),
        price_2: (data.price_2 / 1.1).toFixed(2),
        price_3: (data.price_3 / 1.1).toFixed(2),
        price_4: (data.price_4 / 1.1).toFixed(2),
        price_5: (data.price_5 / 1.1).toFixed(2),
      })
    }else{
      reset({})
    }
  }, [data, modal])

  const autoSetPrice = useCallback(() => {
    let buyPrice = watch("buy_price")
    if (!buyPrice) return showToast("Buy price field is required", "error")
    setValue('price', (buyPrice * 1.25).toFixed(2));
    setValue('price_1', (buyPrice * 1.20).toFixed(2));
    setValue('price_2', (buyPrice * 1.18).toFixed(2));
    setValue('price_3', (buyPrice * 1.16).toFixed(2));
    setValue('price_4', (buyPrice * 1.14).toFixed(2));
    setValue('price_5', (buyPrice * 1.12).toFixed(2));
  }, [])

  return (
    <Modal
      size="lg"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
      zIndex={9999}
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? "Update Product" : "Create Product"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          <Row>
            <Col>
              <div className="mb-3">
                <label>SKU</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="SKU"
                  {...register("sku", { required: true })}
                />
                {formErrors?.sku && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.sku && (
                  <span className="form-text text-danger">{errors.sku[0]}</span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Brand</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("brand", e?.value)
                  }}
                  options={brands?.brands}
                  isLoading={loadingBrand}
                  defaultValue={{
                    label: data?.brand,
                    value: data?.brand,
                  }}
                ></ReactSelect>
                {formErrors?.brand && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.brand && (
                  <span className="form-text text-danger">
                    {errors.brand[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Supplier</label>
                <ReactSelect
                  isClearable
                  isMulti
                  onChange={e => {
                    setValue(
                      "suppliers",
                      e?.map(v => v.value)
                    )
                  }}
                  options={suppliers?.suppliers}
                  isLoading={loadingSupplier}
                  defaultValue={data?.suppliers.map(item => ({
                    label: item,
                    value: item,
                  }))}
                ></ReactSelect>
                {formErrors?.supplier && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.supplier && (
                  <span className="form-text text-danger">
                    {errors.supplier[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Stock Availability</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("in_stock", e?.value)
                  }}
                  options={[
                    { label: "Backorder", value: 4 },
                    { label: "End of Life", value: 3 },
                    { label: "Call Us", value: 2 },
                    { label: "In Stock", value: 1 },
                    { label: "Out of Stock", value: 0 },
                  ]}
                  defaultValue={getProductStatus(data?.in_stock)}
                ></ReactSelect>
                {formErrors?.in_stock && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.in_stock && (
                  <span className="form-text text-danger">
                    {errors.in_stock[0]}
                  </span>
                )}
              </div>
            </Col>
            {watch("in_stock") === 3 ? (
              <Col>
                <div className="mb-3">
                  <label>Alternative Product</label>
                  <ReactSelect
                    isClearable
                    onChange={e => {
                      setValue("alternative_product", e?.value)
                    }}
                    options={products?.products}
                    getOptionValue={value => value.label}
                    getOptionLabel={value => value.label}
                    onInputChange={val => setSearchProduct(val)}
                    defaultValue={{
                      label: data?.alternative_product,
                      value: data?.alternative_product,
                    }}
                  ></ReactSelect>
                  {formErrors?.alternative_product && (
                    <small className="text-danger">
                      This field is required
                    </small>
                  )}
                  {errors.alternative_product && (
                    <span className="form-text text-danger">
                      {errors.alternative_product[0]}
                    </span>
                  )}
                </div>
              </Col>
            ) : null}
            <Col>
              <div className="mb-3">
                <label>Status</label>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    setValue("is_active", e?.value)
                  }}
                  options={[
                    { label: "Enabled", value: true },
                    { label: "Disabled", value: false },
                  ]}
                  defaultValue={{
                    label: data?.is_active ? "Enabled" : "Disabled",
                    value: data?.is_active,
                  }}
                ></ReactSelect>
                {formErrors?.is_active && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.is_active && (
                  <span className="form-text text-danger">
                    {errors.is_active[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register("name", { required: true })}
                />
                {formErrors?.name && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.name && (
                  <span className="form-text text-danger">
                    {errors.name[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <Divider />

          <Row>
            <Col>
              <div className="mb-3">
                <label>Buy Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Buy Price"
                  {...register("buy_price", { required: true })}
                />
                <span>
                  Inc: ${(Number(watch("buy_price")) * 1.1).toFixed(2)}
                </span>
                {formErrors?.buy_price && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.buy_price && (
                  <span className="form-text text-danger">
                    {errors.buy_price[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>RRP Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="RRP Price"
                  {...register("price", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 25% Exc: ${Number(watch("buy_price") * 1.25).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price && (
                  <span className="form-text text-danger">
                    {errors.price[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Trade Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Trade Price"
                  {...register("price_1", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price_1") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price_1")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price_1") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 20% Exc: ${Number(watch("buy_price") * 1.2).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price_1 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_1 && (
                  <span className="form-text text-danger">
                    {errors.price_1[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Silver</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Silver"
                  {...register("price_2", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price_2") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price_2")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price_2") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 18% Exc: ${Number(watch("buy_price") * 1.18).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price_2 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_2 && (
                  <span className="form-text text-danger">
                    {errors.price_2[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Gold</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Gold"
                  {...register("price_3", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price_3") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price_3")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price_3") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 16% Exc: ${Number(watch("buy_price") * 1.16).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price_3 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_3 && (
                  <span className="form-text text-danger">
                    {errors.price_3[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Platinum</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Platinum"
                  {...register("price_4", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price_4") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price_4")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price_4") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 14% Exc: ${Number(watch("buy_price") * 1.14).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price_4 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_4 && (
                  <span className="form-text text-danger">
                    {errors.price_4[0]}
                  </span>
                )}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Diamond</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Diamond"
                  {...register("price_5", { required: true })}
                />
                <small>
                  Profit Exc: $
                  {Number(watch("price_5") - watch("buy_price")).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price Inc: ${(Number(watch("price_5")) * 1.1).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Profit Inc: $
                  {(
                    Number(watch("price_5") * 1.1 - watch("buy_price")) * 1.1
                  ).toFixed(2)}
                </small>{" "}
                <br />
                <small>
                  Price 12% Exc: ${Number(watch("buy_price") * 1.12).toFixed(2)}
                </small>{" "}
                <br />
                {formErrors?.price_5 && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price_5 && (
                  <span className="form-text text-danger">
                    {errors.price_5[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>

          <button
            type="button"
            className="btn btn-primary float-end"
            onClick={autoSetPrice}
          >
            <span>Auto Set Price</span>
          </button>
        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={handleSubmit(mutate)}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);