import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { showToast } from "helpers/utils"
import moment from "moment"
import React, { useMemo, useState } from "react"
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form"
import ReactSelect from "react-select"
import { Table } from "reactstrap"

const RelatedProduct = ({ data, updateField, refresh }) => {
  const [searchProduct, setSearchProduct] = useState("")

  const { register, control, watch, setValue, getValues } =
    useFormContext()
  const relatedProducts = useWatch({ control, name: "related_products" })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "related_products",
  })

  const { data: products, isLoading: loadingProduct } = useQuery(
    ["product-select", searchProduct],
    () => api.productSelect({ params: { search: searchProduct } }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { data: relatedProduct, isLoading: loadingRelatedProduct } = useQuery(
    ["related-product", data._id],
    () => api.relatedProduct({ params: { id: data._id } }),
    {
      onSuccess: (res) => {
        setValue(
          "related_products",
          res.related_products.filter(item => !!item.product).map(item => ({
            product: {
              label: item.product.sku,
              value: item.product._id,
              buy_price: item.product.buy_price,
              price: item.product.price,
              price_1: item.product.price_1,
              price_2: item.product.price_2,
              price_3: item.product.price_3,
              price_4: item.product.price_4,
              price_5: item.product.price_5,
              name: item.product.name,
            },
          }))
        )
      },
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: updateRelatedProduct, isLoading: isUpdateLoading } =
    useMutation(
      params =>
        api.updateRelatedProduct(data._id, getValues("related_products")),
      {
        onSuccess: res => {
          showToast("Successfully update related product")
          if (!res.status) {
            showToast("Failed to update related product", "error")
          }
          refresh()
        },
        onError: err => {
          console.log(err)
          showToast(
            err.response.data ? err.message : "Failed to update product",
            "error"
          )
        },
      }
    )

  return (
    <>
      {loadingRelatedProduct ? (
        <></>
      ) : (
        <div className="mt-3">
          <h4
            className="text-center mt-5 mb-3 text-white w-25 mx-auto p-2"
            style={{ backgroundColor: "#132747" }}
          >
            Related Product
          </h4>
          <table className="table table-bordered py-1">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Product</th>
                <th>Buy Price (Ex GST)</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((related_product, index) => (
                <tr key={related_product.id}>
                  <th>
                    <Controller
                      control={control}
                      // defaultValue={related_products.code}
                      name={`related_products.${index}.product`}
                      render={({ onChange, value, name, ref, field }) => (
                        <ReactSelect
                          inputRef={ref}
                          isLoading={loadingProduct}
                          options={products?.products}
                          // value={suppliers?.suppliers.find(
                          //   c => c.value === value
                          // )}
                          onInputChange={val => setSearchProduct(val)}
                          onChange={val => {
                            onChange(val)
                          }}
                          isClearable
                          defaultValue={related_product.product}
                          {...field}
                        ></ReactSelect>
                      )}
                    />
                  </th>
                  <th width="30%">
                    {watch(`related_products.${index}.product.name`)}
                  </th>
                  <th>
                    $
                    {Number(
                      watch(`related_products.${index}.product.buy_price`) ??
                        0 / 1.1
                    ).toFixed(2)}
                  </th>
                  <td width={50}>
                    <button
                      className="btn btn-danger bg-danger"
                      onClick={() => remove(index)}
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button
            className="btn btn-primary btn-navy float-end"
            onClick={() => append()}
          >
            Add Product
          </button>
        </div>
      )}
    </>
  )
}

export default React.memo(RelatedProduct)
