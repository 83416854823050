import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation(async (params) => {
    return (await data) ? api.updateSupplier(data._id, params) : api.createSupplier(params);
  }, {
    onSuccess: (res) => {
      console.log(res)
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit supplier trade price', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      setErrors([]);
      reset({
        ...data,
      });
    }
  }, [data, modal])

  return (
    <Modal
      size="md"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Supplier' : 'Create Supplier'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          <Row>
            <Col>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register('name', { required: true })}
                />
                {formErrors?.name && <small className="text-danger">This field is required</small>}
                {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  {...register('email', { required: true })}
                />
                {formErrors?.email && <small className="text-danger">This field is required</small>}
                {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Phone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone"
                  {...register('phone', { required: true })}
                />
                {formErrors?.phone && <small className="text-danger">This field is required</small>}
                {errors.phone && <span className="form-text text-danger">{errors.phone[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Telephone</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Telephone"
                  {...register('telephone', { required: data ? false : true })}
                />
                {formErrors?.telephone && <small className="text-danger">This field is required</small>}
                {errors.telephone && <span className="form-text text-danger">{errors.telephone[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Sales Person</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Sales Person"
                  {...register('sales_person', { required: true })}
                />
                {formErrors?.sales_person && <small className="text-danger">This field is required</small>}
                {errors.sales_person && <span className="form-text text-danger">{errors.sales_person[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Company Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Company Name"
                  {...register('company_name', { required: data ? false : true })}
                />
                {formErrors?.company_name && <small className="text-danger">This field is required</small>}
                {errors.company_name && <span className="form-text text-danger">{errors.company_name[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>State</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  {...register('state', { required: true })}
                />
                {formErrors?.state && <small className="text-danger">This field is required</small>}
                {errors.state && <span className="form-text text-danger">{errors.state[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>City</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="City"
                  {...register('city', { required: true })}
                />
                {formErrors?.city && <small className="text-danger">This field is required</small>}
                {errors.city && <span className="form-text text-danger">{errors.city[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Post Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Post Code"
                  {...register('postcode', { required: true })}
                />
                {formErrors?.postcode && <small className="text-danger">This field is required</small>}
                {errors.postcode && <span className="form-text text-danger">{errors.postcode[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Address</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Address"
                  {...register('address', { required: true })}
                />
                {formErrors?.address && <small className="text-danger">This field is required</small>}
                {errors.address && <span className="form-text text-danger">{errors.address[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Website</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Website"
                  {...register('website', { required: true })}
                />
                {formErrors?.website && <small className="text-danger">This field is required</small>}
                {errors.website && <span className="form-text text-danger">{errors.website[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>ABN</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="ABN"
                  {...register('abn', { required: true })}
                />
                {formErrors?.abn && <small className="text-danger">This field is required</small>}
                {errors.abn && <span className="form-text text-danger">{errors.abn[0]}</span>}
              </div>
            </Col>
          </Row>

        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={handleSubmit(mutate)}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);