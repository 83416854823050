import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation(async (params) => {
    return (await data) ? api.updateSupplierTradePrice(data._id, params) : api.createSupplierTradePrice(params);
  }, {
    onSuccess: (res) => {
      console.log(res)
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit supplier trade price', 'error')
    }
  })

  useEffect(() => {
    if (data) {
      const allowed = ['base_url', 'login_url', 'email', 'password', 'name', 'code'];
      const keys = Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = data[key];
          return obj;
        }, {});
      setErrors([]);
      reset({
        ...keys,
      });
    }
  }, [data, modal])

  return (
    <Modal
      size="md"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data ? 'Update Supplier' : 'Create Supplier'}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          <Row>
            <Col>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register('name', { required: true })}
                />
                {formErrors?.name && <small className="text-danger">This field is required</small>}
                {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Code</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Code"
                  {...register('code', { required: true })}
                />
                {formErrors?.code && <small className="text-danger">This field is required</small>}
                {errors.code && <span className="form-text text-danger">{errors.code[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  {...register('email', { required: true })}
                />
                {formErrors?.email && <small className="text-danger">This field is required</small>}
                {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  {...register('password', { required: data ? false : true })}
                />
                {formErrors?.password && <small className="text-danger">This field is required</small>}
                {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Base URL</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Base URL"
                  {...register('base_url', { required: true })}
                />
                {formErrors?.base_url && <small className="text-danger">This field is required</small>}
                {errors.base_url && <span className="form-text text-danger">{errors.base_url[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Login URL</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Login URL"
                  {...register('login_url', { required: true })}
                />
                {formErrors?.login_url && <small className="text-danger">This field is required</small>}
                {errors.login_url && <span className="form-text text-danger">{errors.login_url[0]}</span>}
              </div>
            </Col>
          </Row>

        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary w-md" disabled={submitLoading} onClick={handleSubmit(mutate)}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);