import { useQuery } from '@tanstack/react-query'
import api from 'helpers/api'
import { getProductStatus } from 'helpers/utils'
import moment from 'moment'
import React, { useState } from 'react'
import ReactSelect from 'react-select'

const Information = ({ data, updateField, refresh }) => {
  const [searchProduct, setSearchProduct] = useState("")

  const { data: products, isLoading: loadingProduct } = useQuery(
    ["product-select", searchProduct],
    () => api.productSelect({ params: { search: searchProduct } }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { data: brands, isLoading: loadingBrand } = useQuery(
    ["brand-select"],
    () => api.brandSelect(),
    {
      refetchOnWindowFocus: true,
    }
  )

  const { data: categories, isLoading: loadingCategory } = useQuery(
    ["category-select"],
    () => api.categorySelect(),
    {
      refetchOnWindowFocus: true,
    }
  )

  const { data: suppliers, isLoading: loadingSupplier } = useQuery(
    ["suppliers"],
    () => api.supplierSelect(),
    {
      refetchOnWindowFocus: true,
    }
  )

  return (
    <div className="row mt-3">
      <div className="col">
        <table className="mb-3 w-100">
          <tbody>
            <tr>
              <th>Name</th>
              <th>:</th>
              <td>{data.name}</td>
            </tr>
            <tr>
              <th>SKU</th>
              <th>:</th>
              <td>{data.sku}</td>
            </tr>
            <tr>
              <th>Supplier Part Number</th>
              <th>:</th>
              <td>{data.supplier_part_number ?? "-"}</td>
            </tr>
            <tr>
              <th>Brand</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    updateField({ brand: e?.value })
                  }}
                  options={brands?.brands}
                  isLoading={loadingBrand}
                  defaultValue={{
                    label: data?.brand,
                    value: data?.brand,
                  }}
                ></ReactSelect>
              </td>
            </tr>
            <tr>
              <th>Category</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    updateField({ category: e?.value })
                  }}
                  options={categories?.categories}
                  isLoading={loadingCategory}
                  defaultValue={{
                    label: data?.category,
                    value: data?.category,
                  }}
                ></ReactSelect>
              </td>
            </tr>
            <tr>
              <th>Supplier</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  isMulti
                  onChange={e => {
                    updateField({ suppliers: e?.map(value => value.value) })
                  }}
                  options={suppliers?.suppliers}
                  isLoading={loadingSupplier}
                  defaultValue={data?.suppliers?.filter(item => !!item && typeof item !== 'object')?.map(item => ({
                    label: item,
                    value: item,
                  }))}
                ></ReactSelect>
                {/* {[
                  ...new Map(
                    data.buy_prices.map(item => [item, item])
                  ).values(),
                ]
                  .map(item => item.supplier.name)
                  .join(", ")} */}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="col">
        <table className="mb-3 w-100">
          <tbody>
            <tr>
              <th>Last Update</th>
              <th>:</th>
              <td>{moment(data?.updated_at).format("DD MMM YYYY hh:mm")}</td>
            </tr>
            <tr>
              <th>Person</th>
              <th>:</th>
              <td className="text-warning">
                {data?.updated_by[0]?.name ?? "-"}
              </td>
            </tr>
            <tr>
              <th>Stock Availability</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    updateField({ in_stock: e?.value })
                  }}
                  options={[
                    { label: "Backorder", value: 4 },
                    { label: "End of Life", value: 3 },
                    { label: "Call Us", value: 2 },
                    { label: "In Stock", value: 1 },
                    { label: "Out of Stock", value: 0 },
                  ]}
                  defaultValue={getProductStatus(data?.in_stock)}
                ></ReactSelect>
              </td>
            </tr>
            {data?.in_stock === 3 ? (
              <tr>
                <th>Alternative Product</th>
                <th>:</th>
                <td>
                  <ReactSelect
                    isClearable
                    onChange={e => {
                      updateField({ alternative_product: e?.label })
                    }}
                    options={products?.products}
                    getOptionValue={value => value.label}
                    getOptionLabel={value => value.label}
                    onInputChange={val => setSearchProduct(val)}
                    defaultValue={{
                      label: data?.alternative_product,
                      value: data?.alternative_product,
                    }}
                  ></ReactSelect>
                </td>
              </tr>
            ) : null}
            <tr>
              <th>Product Type</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    updateField({ product_type: e?.value })
                  }}
                  options={[
                    { label: "Individual", value: 0 },
                    { label: "Product Kit", value: 1 },
                  ]}
                  defaultValue={{
                    label: data?.product_type ? "Product Kit" : "Individual",
                    value: data?.product_type,
                  }}
                ></ReactSelect>
              </td>
            </tr>
            <tr>
              <th>Status</th>
              <th>:</th>
              <td>
                <ReactSelect
                  isClearable
                  onChange={e => {
                    updateField({ is_active: e?.value })
                  }}
                  options={[
                    { label: "Enabled", value: true },
                    { label: "Disabled", value: false },
                  ]}
                  defaultValue={{
                    label: data?.is_active ? "Enabled" : "Disabled",
                    value: data?.is_active,
                  }}
                ></ReactSelect>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default React.memo(Information);