import { titleCase } from 'helpers/utils';
import moment from 'moment';
import React, { useMemo } from 'react'
import { Table } from 'reactstrap';

const Stock = ({ data, updateField, refresh }) => {

  return (
    <>
      {data?.leader_stock ? (
        <>
          <h4 className="text-center mb-3 bg-primary text-white py-2">
            Stock Leader System
          </h4>

          <Table className="table mb-5 table-bordered">
            <thead
              className="text-white"
              style={{ backgroundColor: "#132747" }}
            >
              <tr className="text-center">
                {Object.keys(JSON.parse(data?.leader_stock)).map(stock => (
                  <th key={stock}>{stock}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                {data?.leader_stock ? (
                  Object.keys(JSON.parse(data?.leader_stock)).map(stock => (
                    <th
                      key={stock}
                      className={
                        JSON.parse(data?.leader_stock)[stock] == 0
                          ? "text-danger"
                          : "text-success"
                      }
                    >
                      {JSON.parse(data?.leader_stock)[stock]
                        ? JSON.parse(data?.leader_stock)[stock]
                        : 0}
                    </th>
                  ))
                ) : (
                  <></>
                )}
              </tr>
            </tbody>
          </Table>
        </>
      ) : null}
    </>
  )
}

export default React.memo(Stock);