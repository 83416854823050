import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import { Divider } from 'antd';

const UpdateSupplierBuyPrice = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const [suppliers, setSuppliers] = useState([])
  const { register, setValue, handleSubmit, reset, formState: { formErrors } } = useForm();

  const { mutate, isLoading: submitLoading } = useMutation(
    params =>
      data?.supplier?._id
        ? api.updateSupplierBuyingPrice(data?._id, params)
        : api.createSupplierBuyingPrice(params),
    {
      onSuccess: res => {
        showToast(res.message, !res.status && "error")
        if (!res.status) {
          setErrors(res.errors)
          return
        }
        toggle()
        refresh()
      },
      onError: err => {
        showToast("Failed to update price", "error")
      },
    }
  )

  const { data: supplierData, isLoading: loadingSuppliers } = useQuery(
    ["buy-price-supplier-select"],
    () => api.selectSupplierBuyPrice(),
    {
      onSuccess: (res) => {
        const buyPriceIds = data?.buy_prices.map(item => item.supplier._id)
        setSuppliers(res.suppliers.filter(supplier => !buyPriceIds.includes(supplier.value)))
        return res;
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !data?.supplier?._id,
    }
  )

  useEffect(() => {
    setErrors([]);
    reset({
      ...data,
      price: data?.price?.toFixed(2)
    });
  }, [data, modal])

  return (
    <Modal size="md" toggle={() => handleCreateModal()} isOpen={modal} centered zIndex={9999}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {data?.supplier?._id ? "Update Buy Price" : "Create Buy Price"}
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <form onSubmit={handleSubmit(mutate)}>
          {!data?.supplier?._id ? (
            <Row>
              <Col>
                <div className="mb-3">
                  <label>Supplier</label>
                  <ReactSelect
                    isClearable
                    onChange={e => {
                      setValue("supplier_id", e?.value)
                    }}
                    options={suppliers}
                  ></ReactSelect>
                  {formErrors?.supplier && (
                    <small className="text-danger">
                      This field is required
                    </small>
                  )}
                  {errors.supplier && (
                    <span className="form-text text-danger">
                      {errors.supplier[0]}
                    </span>
                  )}
                </div>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col>
              <div className="mb-3">
                <label>Buy Price</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Buy Price"
                  {...register("price", { required: true })}
                />
                {formErrors?.price && (
                  <small className="text-danger">This field is required</small>
                )}
                {errors.price && (
                  <span className="form-text text-danger">
                    {errors.price[0]}
                  </span>
                )}
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button
            type="submit"
            className="btn btn-primary w-md"
            disabled={submitLoading}
            onClick={handleSubmit(mutate)}
          >
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(UpdateSupplierBuyPrice);