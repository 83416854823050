import React, { useState } from "react"
import { Modal, Nav, NavItem, NavLink, Spinner, TabContent, TabPane } from "reactstrap"
import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { showToast } from "helpers/utils"
import Price from "../Component/Price"
import Information from "../Component/Show/Information"
import BuyPriceImport from "../Component/Show/BuyPriceImport"
import PriceImport from "../Component/Show/PriceImport"
import TradePriceImport from "../Component/Show/TradePriceImport"
import Stock from "../Component/Show/Stock"
import BuyPriceHistory from "../Component/Show/BuyPriceHistory"
import PriceHistory from "../Component/Show/PriceHistory"
import TradePriceHistory from "../Component/Show/TradePriceHistory"
import ProductKit from "../Component/Show/ProductKit"
import Additional from "../Component/Show/Additional"
import PopupModal from "components/Common/PopupModal"
import { useModalWindow } from "react-modal-global"
import StockLeader from "../Component/Show/StockLeader"
import StockAlloys from "../Component/Show/StockAlloys"
import CreateUpdate from "./CreateUpdate"
import Log from "../Component/Show/Log"

const Show = ({ toggle, modal, handleCreateModal, tradeType = 1, refresh, data }) => {
  const modalWindow = useModalWindow()
  const [activeTab, setActiveTab] = useState('1')
  const [modalEdit, setModalEdit] = useState(false)
  const [modalData, setModalData] = useState(null)
  const { mutate: updateField, isLoading: updateFieldLoading } = useMutation(
    params => api.updateFieldProduct(data._id, params),
    {
      onSuccess: res => {
        showToast("Successfully update product")
        if (!res.status) {
          showToast("Failed to update product", "error")
        }
        refresh()
      },
      onError: err => {
        console.log(err)
        showToast(
          err.response.data ? err.message : "Failed to update product",
          "error"
        )
      },
    }
  )

  const {
    refetch,
    data: product,
    isLoading: loadingProduct,
  } = useQuery(["product-show", data], () =>
    api.product({ params: { id: data._id } })
  )

  const refreshData = () => {
    refetch()
    refresh()
  }

  const toggleEdit = () => {
    if (modalEdit) {
      setModalEdit(false)
      setModalData(null)
    } else {
      setModalEdit(true)
      setModalData(data)
    }
  }

  return (
    <>
      <PopupModal>
        <div className="modal-header">
          <h5 className="modal-title mt-0 text-center w-100">Product</h5>
          <button
            onClick={modalWindow.close}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {data ? (
            <>
              <Nav tabs justified pills>
                <NavItem>
                  <NavLink
                    active={activeTab == "1"}
                    onClick={() => setActiveTab("1")}
                  >
                    Product
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab == "2"}
                    onClick={() => setActiveTab("2")}
                  >
                    Buy Price
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab == "3"}
                    onClick={() => setActiveTab("3")}
                  >
                    RRP
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab == "4"}
                    onClick={() => setActiveTab("4")}
                  >
                    Trade Price
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    active={activeTab == "5"}
                    onClick={() => setActiveTab("5")}
                  >
                    Additional
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-outline-primary mt-3 mr-auto"
                  onClick={toggleEdit}
                >
                  Edit <i className="bx bx-pencil"></i>
                </button>
              </div>
              <TabContent activeTab={activeTab}>
                {!loadingProduct ? (
                  <>
                    <TabPane tabId="1">
                      <>
                        <Information
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <Price
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <Stock
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <StockAlloys
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <StockLeader
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <ProductKit
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                      </>
                    </TabPane>
                    <TabPane tabId="2">
                      <>
                        <BuyPriceImport
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <BuyPriceHistory
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                      </>
                    </TabPane>
                    <TabPane tabId="3">
                      <>
                        <PriceImport
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <PriceHistory
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                      </>
                    </TabPane>
                    <TabPane tabId="4">
                      <>
                        <TradePriceImport
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <TradePriceHistory
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                      </>
                    </TabPane>
                    <TabPane tabId="5">
                      <>
                        <Additional
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <Log
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        {/* <RelatedProduct
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <CrossSell
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        />
                        <UpSell
                          data={product}
                          updateField={updateField}
                          refresh={refreshData}
                        /> */}
                      </>
                    </TabPane>
                  </>
                ) : (
                  <Spinner
                    className="mt-3 spinner-loading mx-auto position-relative"
                    style={{ left: "50%" }}
                    color="success"
                  />
                )}
              </TabContent>
            </>
          ) : null}
        </div>
        {modalEdit && (
          <CreateUpdate
            modal={modalEdit}
            toggle={toggleEdit}
            handleCreateModal={toggleEdit}
            data={modalData}
            refresh={refreshData}
          />
        )}
      </PopupModal>
    </>
  )
}

export default React.memo(Show)
