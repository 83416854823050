import { all, call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_MODAL_DATA, OPEN_MODAL, SET_MODAL_DATA } from "./actionTypes"
import { getModalDataset, setModalDataset, setOpenModal } from "./actions"

//Include Both Helper File with needed methods
import { getTasks } from "helpers/fakebackend_helper"

function* setOpen(payload) {
  try {
    // console.log(OPEN_MODAL, payload.payload)
    yield put({type: OPEN_MODAL, payload: payload.payload})
  } catch (error) {
    console.log(error)
  }
}

function* setModal(payload) {
  try {
    yield put({type: SET_MODAL_DATA, payload: payload.payload})
    // yield put(setModalDataset(payload))
  } catch (error) {
    console.log(error)
  }
}

function* getModal() {
  try {
    yield put(getModalDataset())
  } catch (error) {
    console.log(error)
  }
}

function* modalSaga() {
  yield all(OPEN_MODAL, setOpen)
  yield all(SET_MODAL_DATA, setModal)
  yield takeEvery(GET_MODAL_DATA, getModal)
}

export default modalSaga
