import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import moment from 'moment';
import React, { useMemo, useState } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import ReactSelect from 'react-select';
import { Table } from 'reactstrap';

const ProductKit = ({ data, updateField, refresh }) => {
  const [searchProduct, setSearchProduct] = useState("")

  const { register, control, watch, getValues } = useForm({
    defaultValues: {
      id: data._id,
      product_kits: data.kits.map(item => ({
        product: {
          label: item.product.sku,
          value: item.product._id,
          buy_price: item.product.buy_price,
          price: item.product.price,
          price_1: item.product.price_1,
          price_2: item.product.price_2,
          price_3: item.product.price_3,
          price_4: item.product.price_4,
          price_5: item.product.price_5,
          name: item.product.name,
        },
        qty: item.qty,
      })),
    },
  })

  const { fields, append, remove } = useFieldArray({
    control,
    name: "product_kits",
  })

  const { data: products, isLoading: loadingProduct } = useQuery(
    ["product-select", searchProduct],
    () => api.productSelect({ params: { search: searchProduct } }),
    {
      enabled: !!searchProduct,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  )

  const { mutate: updateProductKits, isLoading: isUpdateKits } = useMutation(
    params => api.updateKitProduct(data._id, getValues("product_kits")),
    {
      onSuccess: res => {
        showToast("Successfully update product kit")
        if (!res.status) {
          showToast("Failed to update product kit", "error")
        }
        refresh()
      },
      onError: err => {
        console.log(err)
        showToast(
          err.response.data ? err.message : "Failed to update product",
          "error"
        )
      },
    }
  )

  return (
    <>
      {data?.product_type == 1 ? (
        <>
          <h4>Product Kits</h4>
          <table className="table table-bordered py-1">
            <thead>
              <tr>
                <th>SKU</th>
                <th>Product</th>
                <th>Buy Price (Ex GST)</th>
                <th>QTY</th>
              </tr>
            </thead>
            <tbody>
              {fields.map((product_kit, index) => (
                <tr key={product_kit.id}>
                  <th>
                    <Controller
                      control={control}
                      // defaultValue={product_kits.code}
                      name={`product_kits.${index}.product`}
                      render={({ onChange, value, name, ref, field }) => (
                        <ReactSelect
                          inputRef={ref}
                          isLoading={loadingProduct}
                          options={products?.products}
                          name={name}
                          // value={suppliers?.suppliers.find(
                          //   c => c.value === value
                          // )}
                          onInputChange={val => setSearchProduct(val)}
                          onChange={val => {
                            console.log(val)
                            onChange(val)
                          }}
                          isClearable
                          defaultValue={product_kit?.product}
                          {...field}
                        ></ReactSelect>
                      )}
                    />
                  </th>
                  <th width="30%">
                    {watch(`product_kits.${index}.product.name`)}
                  </th>
                  <th>
                    $
                    {Number(
                      watch(`product_kits.${index}.product.buy_price`) / 1.1 ??
                        0
                    ).toFixed(2)}
                  </th>
                  <th width={`20%`}>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="QTY"
                      // defaultValue={product_kits.url}
                      {...register(`product_kits.${index}.qty`)}
                    />
                  </th>
                  <td width={50}>
                    <button
                      className="btn btn-danger bg-danger"
                      onClick={() => remove(index)}
                    >
                      <i className="bx bx-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <button
            className="btn btn-primary btn-navy float-end"
            onClick={() => append({ product: null, qty: null })}
          >
            Add Product
          </button>
          <button
            className="btn btn-primary btn-navy w-100 my-3"
            onClick={() => updateProductKits()}
          >
            {isUpdateKits ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Save Product Kits</span>
            )}
          </button>
        </>
      ) : null}
    </>
  )
}

export default React.memo(ProductKit);