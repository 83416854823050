import { useMutation, useQuery } from "@tanstack/react-query"
import api from "helpers/api"
import { showToast } from "helpers/utils"
import moment from "moment"
import React, { useEffect, useMemo, useState } from "react"
import { Controller, FormProvider, useFieldArray, useForm, useWatch } from "react-hook-form"
import ReactSelect from "react-select"
import { Table } from "reactstrap"
import RelatedProduct from "./RelatedProduct"
import CrossSell from "./CrossSell"
import UpSell from "./UpSell"

const Additional = ({ data, updateField, refresh }) => {
  const methods = useForm()

  const { mutate: updateUpSellProduct, isLoading: isUpdateLoading } =
    useMutation(
      // params => console.log(methods.getValues()),
      () => api.updateAdditional(data._id, methods.getValues()),
      {
        onSuccess: res => {
          showToast("Successfully update up sell product")
          if (!res.status) {
            showToast("Failed to update up sell product", "error")
          }
          refresh()
        },
        onError: err => {
          console.log(err)
          showToast(
            err.response.data ? err.message : "Failed to update product",
            "error"
          )
        },
      }
    )

  useEffect(() => {
    methods.setValue('id', data._id)
  }, [])

  return (
    <>
      <div className="mt-3">
        <FormProvider {...methods}>
          <RelatedProduct
            data={data}
            updateField={updateField}
            refresh={refresh}
            methods={methods}
          />
          <CrossSell
            data={data}
            updateField={updateField}
            refresh={refresh}
            methods={methods}
          />
          <UpSell
            data={data}
            updateField={updateField}
            refresh={refresh}
            methods={methods}
          />
          <button
            className="btn btn-primary btn-navy w-100 my-3"
            onClick={() => methods.handleSubmit(updateUpSellProduct())}
          >
            {isUpdateLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Save All</span>
            )}
          </button>
        </FormProvider>
      </div>
    </>
  )
}

export default React.memo(Additional)
