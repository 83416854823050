import React, { useEffect, useRef, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import { Pagination } from 'react-laravel-paginex';
import Breadcrumbs from "components/Common/Breadcrumb";
import { Button, Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, Table, FormGroup, Label, Row, Spinner, UncontrolledButtonDropdown, UncontrolledDropdown, Nav, NavItem, NavLink, TabContent, TabPane, ModalBody, ModalFooter } from 'reactstrap';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import CreateUpdate from './Action/CreateUpdate';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import { getImageUrl, getProductStatus, showConfirm, showToast } from 'helpers/utils';
import { del } from 'helpers/api_helper';
import DeleteConfirmation from 'components/Common/DeleteConfirmation';
import ReactSelect from 'react-select';
import Show from './Action/Show';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import queryString from 'query-string';
import UpdateBuyPriceBySupplier from './Action/UpdateBuyPriceBySupplier';
import moment from 'moment';
import { connect, useDispatch, useSelector } from 'react-redux';
import { setModalDataset, setOpenModal } from 'store/actions';
import { ModalContainer } from 'react-modal-global';
import Modal from '../../Modal';
import PopupModal from 'components/Common/PopupModal';
import ViewErrorAutoPriceLog from './Action/ViewErrorAutoPriceLog';

const Product = ({ modalDataset, openModal, setModalDataset, setOpenModal }) => {
  const dispatch = useDispatch()
  const [deleteId, setDeleteId] = useState(null)
  const [modal, setModal] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [modalUpdateBuyPrice, setModalUpdateBuyPrice] = useState(false);
  const [modalData, setModalData] = useState(null)
  const [search, setSearch] = useState('')
  const [page, setPage] = useState(1)
  const [show, setShow] = useState(10)
  const [showDelete, setShowDelete] = useState(false)
  const [filterType, setFilterType] = useState(null)
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [syncActive, setSyncActive] = useState(false)
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  const priceImportRef = useRef(null)
  const productImportRef = useRef(null)

  const [activeTab, setActiveTab] = useState('1');
  const [logs, setLogs] = useState([])
  const [pageLog, setPageLog] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(20)
  const [loading, setLoading] = useState(false)
  const [items, setItems] = useState([])

  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);

  const toggleModal = () => {
    setOpen(!open)
  };

  const handlePagination = (pageNumber) => {
    setPage(pageNumber);
    getLogSupplierStockUpdate(pageNumber, perPage)
  };

  const getAutoPriceLog = async (page = 1, perPage = 10) => {
    api.getLogAutoSetPrice({ page, perPage }).then((res) => {
      if (res.status) {
        setLogs(res.data)
        setPageLog(res.page)
        setTotal(res.count)
        setPerPage(res.perPage)
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  useEffect(() => {
    if (activeTab == '2') {
      getAutoPriceLog()
    }
  }, [activeTab])

  const { mutate: autoSetPrice, isLoading: autoSetPriceLoading } = useMutation(
    params =>
      api.autoSetPrice({
        id: isCheck,
        brand: selectedBrand,
        category: selectedCategory,
        ...params
      }),
    {
      onSuccess: res => {
        if (res.status) {
          showToast(res.message)
        } else {
          showToast(res.message, "error")
        }
        getData()
      },
      onError: err => {
        showToast("Failed to auto set price", "error")
      },
    }
  )

  const { mutate: priceUpdate, isLoading: priceUpdateLoading } = useMutation(
    params =>
      api.syncProductData({
        id: isCheck,
        brand: selectedBrand,
        category: selectedCategory,
        filterType,
      }),
    {
      onSuccess: res => {
        showToast("Successfully update price")
        if (!res.status) {
          showToast("Failed to update price", "error")
        }
        getData()
      },
      onError: err => {
        showToast("Failed to update price", "error")
      },
    }
  )

  const { mutate: tradePriceUpdate, isLoading: tradePriceUpdateLoading } = useMutation((params) => api.syncTradePriceUpdateToServer({ id: isCheck, brand: selectedBrand, category: selectedCategory, filterType }), {
    onSuccess: (res) => {
      showToast('Successfully update trade price')
      if (!res.status) {
        showToast('Failed to update trade price', 'error')
      }
    },
    onError: (err) => {
      showToast('Failed to update trade price', 'error')
    }
  })

  const { mutate: buyPriceUpdate, isLoading: buyPriceUpdateLoading } = useMutation((params) => api.syncBuyPriceUpdateToServer({ id: isCheck, brand: selectedBrand, category: selectedCategory, filterType }), {
    onSuccess: (res) => {
      showToast('Successfully update buy price')
      if (!res.status) {
        showToast('Failed to update buy price', 'error')
      }
    },
    onError: (err) => {
      showToast('Failed to update buy price', 'error')
    }
  })

  const { mutate: stockUpdate, isLoading: stockUpdateLoading } = useMutation(() => api.sendStockUpdate(), {
    onSuccess: (res) => {
      showToast('Successfully update stock')
      if (!res.status) {
        showToast('Failed to update stock', 'error')
      }
    },
    onError: (err) => {
      showToast('Failed to update stock', 'error')
    }
  })

  const { mutate: priceImport, isLoading: priceImportLoading } = useMutation((params) => {
    const formData = new FormData();
    formData.append('file', params)
    return api.importBuyPriceProduct(formData)
  }, {
    onSuccess: (res) => {
      if (res.status) {
        showToast(res.message)
      } else {
        showToast(res.message, 'error')
      }
      getData();
      priceImportRef.current.value = ''
    },
    onError: (err) => {
      console.log(err)
      priceImportRef.current.value = ''
      showToast(err.response.data ? err.response.data.message : 'Failed to import price', 'error')
    }
  })

  const { mutate: productImport, isLoading: productImportLoading } = useMutation((params) => {
    const formData = new FormData();
    formData.append('file', params)
    return api.importProduct(formData)
  }, {
    onSuccess: (res) => {
      showToast('Successfully import product')
      if (!res.status) {
        showToast('Failed to import product', 'error')
      }
      getData();
    },
    onError: (err) => {
      console.log(err)
      showToast(err.response.data ? err.message : 'Failed to import price', 'error')
    }
  })

  const { mutate: priceUpdateCheapest, isLoading: priceUpdateCheapestLoading } = useMutation((params) => api.updatePriceUpdateCheapest(params), {
    onSuccess: (res) => {
      showToast('Successfully update product from cheapest price')
      if (!res.status) {
        showToast('Failed to update product from cheapest price', 'error')
      }
      getData();
    },
    onError: (err) => {
      console.log(err)
      showToast(err.response.data ? err.message : 'Failed to update price', 'error')
    }
  })

  const { mutate: buyPriceUpdateCheapest, isLoading: buyPriceUpdateCheapestLoading } = useMutation((params) => api.updateBuyPriceUpdateCheapest(params), {
    onSuccess: (res) => {
      showToast('Successfully update product from cheapest price')
      if (!res.status) {
        showToast('Failed to update product from cheapest price', 'error')
      }
      getData();
    },
    onError: (err) => {
      console.log(err)
      showToast(err.response.data ? err.message : 'Failed to update price', 'error')
    }
  })

  const { mutate: tradePriceUpdateCheapest, isLoading: tradePriceUpdateCheapestLoading } = useMutation((params) => api.updateTradePriceUpdateCheapest(params), {
    onSuccess: (res) => {
      showToast('Successfully update product from cheapest price')
      if (!res.status) {
        showToast('Failed to update product from cheapest price', 'error')
      }
      getData();
    },
    onError: (err) => {
      console.log(err)
      showToast(err.response.data ? err.message : 'Failed to update price', 'error')
    }
  })

  const {
    mutate: handlePushProductAE,
    isLoading: pushProductLoading,
  } = useMutation(params => api.pushProductAE(params), {
    onSuccess: res => {
      showToast("Successfully push product")
      if (!res.status) {
        showToast("Failed to push product", "error")
      }
      getData()
    },
    onError: err => {
      console.log(err)
      showToast(
        err.response.data ? err.message : "Failed to update price",
        "error"
      )
    },
  })

  const { mutate: updateField, isLoading: updateFieldLoading } = useMutation(
    params => api.updateFieldProduct(params.id, params),
    {
      onSuccess: res => {
        showToast("Successfully update product")
        if (!res.status) {
          showToast("Failed to update product", "error")
        }
        getData()
      },
      onError: err => {
        console.log(err)
        showToast(
          err.response.data ? err.message : "Failed to update product",
          "error"
        )
      },
    }
  )

  const { data: syncRes, refetch: syncronize, isLoading: isSyncLoading } = useQuery(['sync-product'], () => api.syncProduct(), {
    onSuccess: () => {
      showToast('Successfully syncronize products')
      setSyncActive(false)
      return;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    enabled: syncActive
  });

  const { data: brands } = useQuery(
    ['brand-lists'],
    () => api.brandList(),
    {
      refetchOnWindowFocus: false,
    });

  const { data: categories } = useQuery(
    ['categories'],
    () => api.categorySelect(),
    {
      refetchOnWindowFocus: false,
    });

  const {
    data,
    refetch: getData,
    isLoading,
  } = useQuery(
    [
      "products",
      search,
      page,
      selectedBrand,
      selectedCategory,
      show,
      filterType,
    ],
    () =>
      api.product({
        params: {
          limit: show,
          page: page,
          search: search,
          brand: selectedBrand,
          category: selectedCategory,
          filterType: filterType,
        },
      }),
    {
      onSuccess: (res) => {
        console.log(modalDataset)
        if (modalShow) {
          if (res.data.find(item => item._id == modalDataset._id)) {
            Modal.replaceNamed("Show", {
              label: "ShowProduct",
              refresh: getData,
              data: res.data.find(item => item._id == modalDataset._id),
            }).on("close", () => {
              setModalShow(false)
            })
            // dispatch(setModalDataset(res.data.find(item => item._id == modalDataset._id)))
          } else {
            Modal.closeAll();
          }
        }
      },
      refetchOnWindowFocus: false,
    }
  )

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };

  const toggleUpdateBuyPrice = () => {
    if (modalUpdateBuyPrice) {
      setModalUpdateBuyPrice(false)
    } else {
      setModalUpdateBuyPrice(true)
    }
  }

  const handleCreateModal = () => {
    setModalData(null)
    toggle();
  };

  const handleUpdateModal = (data) => {
    setModalData(data)
    toggle();
  };

  const handleUpdateBuyPriceModal = (data) => {
    setModalData(data)
    toggleUpdateBuyPrice();
  };

  const handleShowModal = (data) => {
    setModalDataset(data)
    setModalShow(true)
    Modal.openNamed("Show", {
      label: "ShowProduct",
      refresh: getData,
      data: data,
    }).on("close", () => {
      console.log("close")
      setModalShow(false)
    })

    // toggleShow();
  };

  const deleteAction = async () => {
    const products = await del(`/products/${deleteId}`);
    setShowDelete(false)
    getData();
  }

  const handleSelectAll = e => {
    setIsCheckAll(!isCheckAll);
    if (isCheckAll) {
      setIsCheck([]);
    } else {
      setIsCheck(data.data.map(li => li._id));
    }
  };

  const selectRow = {
    mode: "checkbox",
    hideSelectColumn: false,
    hideSelectAll: false,
    onSelectAll: (isSelect, rows, e) => {
    },
    onSelect: (row, isSelect, rowIndex, e) => {
      if (isSelect) {
        setIsCheck(prev => [...prev, row._id])
      } else {
        setIsCheck(prev => prev.filter(item => item != row._id))
      }
    },
    selected: isCheck,
  };

  //pagination customization
  const pageOptions = {
    page: 1,
    sizePerPage: show,
    pageStartIndex: 1,
    totalSize: data?.total,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: "id",
      sort: "desc",
      order: 'desc',
    },
  ];

  const ProductColumns = toggleModal => [
    {
      dataField: "_id",
      hidden: true,
    },
    {
      dataField: "sku",
      text: "SKU",
      formatter: (cellContent, row, i) => (
        <>
          <a
            onClick={() => handleShowModal(row)}
            className={!row?.is_active ? "text-danger" : null}
          >
            {row.sku}{" "}
            <div className="d-flex">
              {row?.is_uploaded ? (
                <div>
                  AE <span className="dot green mx-2"></span>
                </div>
              ) : (
                <div>
                  AE <span className="dot red mx-2"></span>
                </div>
              )}
              {row?.is_uploaded_sm ? (
                <div>
                  SM <span className="dot green mx-2"></span>
                </div>
              ) : (
                <div>
                  SM <span className="dot red mx-2"></span>
                </div>
              )}
            </div>
          </a>
          {/* {row.stock ? (
            <div>
              {Object.values(JSON.parse(row.stock))?.some(
                item => item == "In Stock"
              ) ? (
                <small className="text-success">In Stock</small>
              ) : Object.values(JSON.parse(row.stock))?.some(
                  item => item == "Low Stock"
                ) ? (
                <small className="text-warning">Low Stock</small>
              ) : Object.values(JSON.parse(row.stock))?.every(
                  item => item == "Out of Stock"
                ) ? (
                <small className="text-danger">Out of Stock</small>
              ) : (
                "-"
              )}
            </div>
          ) : null} */}
        </>
      ),
    },
    {
      dataField: "name",
      text: "Name",
      maxWidth: 200,
      style: (colum, colIndex) => {
        return { width: "300px", textWrap: "wrap" }
      },
    },
    {
      dataField: "brand",
      text: "Brand",
      formatter: (cellContent, row, i) => {
        let brandElement = null
        if (row.in_stock == 1) {
          brandElement = (
            <small className="text-success">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        } else if (row.in_stock == 4 || row.in_stock == 2) {
          brandElement = (
            <small className="text-warning">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        } else if (row.in_stock == 0 || row.in_stock == 3) {
          brandElement = (
            <small className="text-danger">
              {getProductStatus(row.in_stock).label}
            </small>
          )
        }
        return (
          <>
            <div>{row.brand}</div>
            {brandElement}
            {row.in_stock == 3 ? (
              <small className="text-warning d-block">Alternative</small>
            ) : null}
          </>
        )
      },
    },
    {
      dataField: "category",
      text: "Category",
    },
    {
      dataField: "price",
      text: "Price (Exclude GST)",
      formatter: (cellContent, row, i) => (
        <>
          <div>
            <b>Buy Prices: {`$${Number(row.buy_price / 1.1).toFixed(2)}`}</b>
          </div>
          <div>
            <b>RRP Price: {`$${Number(row.price / 1.1).toFixed(2)}`}</b>
          </div>
          <div>
            <b>Trade Price: {`$${Number(row.price_1 / 1.1).toFixed(2)}`}</b>
          </div>
        </>
      ),
    },
    {
      dataField: "suppliers",
      text: "Suppliers",
      formatter: (cellContent, row, i) => {
        return row?.buy_prices.map(item => (
          <div key={item.id}>{`${item.supplier.name} ($${item.price})`}</div>
        ))
        // let supplier = row?.buy_prices?.reduce(
        //   (prev, curr) => (prev.price < curr.price ? prev : curr),
        //   0
        // )
        // return `${
        //   supplier?.price
        //     ? `${supplier?.supplier?.name} ($${supplier?.price})`
        //     : "-"
        // }`
      },
    },
    {
      dataField: "updated_at",
      text: "Last Updated",
      formatter: (cellContent, row, i) => {
        return (
          <div className="text-center">
            <div>{moment(row?.updated_at).format("DD MMM YYYY hh:mm")}</div>
            <div>{row?.updated_by[0]?.name ?? "-"}</div>
          </div>
        )
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <>
          <UncontrolledButtonDropdown direction="start">
            <DropdownToggle caret>Action</DropdownToggle>
            <DropdownMenu>
              {!row.is_uploaded ? (
                <DropdownItem
                  onClick={() =>
                    showConfirm(() => handlePushProductAE({ id: row._id }))
                  }
                >
                  Push to Alarm Expert
                </DropdownItem>
              ) : null}
              <DropdownItem onClick={() => handleUpdateBuyPriceModal(row)}>
                Update Buy Price to Selected Supplier
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  showConfirm(() => buyPriceUpdateCheapest({ id: row._id }))
                }
              >
                Update Buy Price to Cheapest Price
              </DropdownItem>
              <DropdownItem
                onClick={() =>
                  showConfirm(() =>
                    updateField({ id: row._id, is_active: row.is_active ? false : true })
                  )
                }
              >
                {row.is_active ? "Disable Product" : "Enable Product"}
              </DropdownItem>
              <DropdownItem onClick={() => handleUpdateModal(row)}>
                Edit
              </DropdownItem>
              <DropdownItem
                onClick={() => {
                  setDeleteId(row._id)
                  setShowDelete(true)
                }}
              >
                Delete
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      ),
    },
  ]

  // console.log(getModalData())

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Product" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody className="border-bottom">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={activeTab == '1' ? 'active' : ''}
                        onClick={() => setActiveTab('1')}
                      >
                        Product List
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={activeTab == '2' ? 'active' : ''}
                        onClick={() => setActiveTab('2')}
                      >
                        Logs
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row className='mt-2'>
                        <div className="d-flex align-items-center">
                          <h5 className="mb-0 card-title flex-grow-1">
                            Product Lists
                          </h5>
                          <div>
                            <div className="flex-shrink-0 text-end mb-3">
                              <input
                                type="file"
                                id="price-import"
                                ref={priceImportRef}
                                style={{ display: "none" }}
                                onChange={e => priceImport(e.target.files[0])}
                              />
                              <input
                                type="file"
                                id="product-import"
                                ref={productImportRef}
                                style={{ display: "none" }}
                                onChange={e => productImport(e.target.files[0])}
                              />

                              <UncontrolledDropdown
                                className="dropdown d-inline-block me-1"
                                disabled={priceUpdateLoading}
                              >
                                <DropdownToggle
                                  type="menu"
                                  className="btn btn-light"
                                  id="dropdownMenuButton1"
                                >
                                  Create <i className="mdi mdi-plus"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                  <li>
                                    <DropdownItem onClick={() => handleCreateModal()}>
                                      Manual
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() => {
                                        productImportRef.current.click()
                                      }}
                                    >
                                      Import CSV
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </div>
                            <div className="flex-shrink-0">
                              <Button
                                className="btn btn-light me-1"
                                disabled={priceUpdateLoading}
                                onClick={() => {
                                  priceImportRef.current.click()
                                }}
                              >
                                Import Update Price CSV
                                {priceUpdateLoading ? (
                                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                ) : (
                                  <i className="bx bx-box ms-1"></i>
                                )}
                              </Button>

                              {/* <Button
                          className="btn btn-light me-1"
                          disabled={autoSetPriceLoading}
                          onClick={() =>
                            autoSetPrice({
                              brand: selectedBrand,
                              category: selectedCategory,
                            })
                          }
                        >
                          {isCheck.length > 0
                            ? "Auto Set Price Selected Product"
                            : selectedBrand || selectedCategory
                            ? "Auto Set Price by Filter"
                            : "Auto Set Price"}{" "}
                          {autoSetPriceLoading ? (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                          ) : null}
                        </Button> */}

                              <UncontrolledDropdown
                                className="dropdown d-inline-block me-1"
                                disabled={autoSetPriceLoading}
                              >
                                <DropdownToggle
                                  type="menu"
                                  className="btn btn-light text-nowrap"
                                  id="dropdownMenuButton1"
                                >
                                  {isCheck.length > 0
                                    ? "Auto Set Price Selected Product"
                                    : selectedBrand || selectedCategory
                                      ? "Auto Set Price by Filter"
                                      : "Auto Set Price"}{" "}
                                  {autoSetPriceLoading ? (
                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                  ) : (
                                    <i className="mdi mdi-dots-vertical"></i>
                                  )}
                                </DropdownToggle>
                                <DropdownMenu style={{ top: "0px !important" }}>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          autoSetPrice({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                            type: "all",
                                          })
                                        )
                                      }
                                    >
                                      All Price
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          autoSetPrice({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                            type: "0",
                                          })
                                        )
                                      }
                                    >
                                      RRP Price
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          autoSetPrice({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                            type: "1",
                                          })
                                        )
                                      }
                                    >
                                      Trade Price
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          autoSetPrice({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                            type: "2-5",
                                          })
                                        )
                                      }
                                    >
                                      Silver - Diamond
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                              <UncontrolledDropdown
                                className="dropdown d-inline-block me-1"
                                disabled={buyPriceUpdateLoading}
                              >
                                <DropdownToggle
                                  type="menu"
                                  className="btn btn-light text-nowrap"
                                  id="dropdownMenuButton1"
                                >
                                  {selectedBrand || selectedCategory
                                    ? "Update Supplier Price by Filter"
                                    : "Update Supplier Price"}{" "}
                                  {buyPriceUpdateLoading ? (
                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                  ) : (
                                    <i className="mdi mdi-dots-vertical"></i>
                                  )}
                                </DropdownToggle>
                                <DropdownMenu style={{ top: "0px !important" }}>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          priceUpdateCheapest({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                          })
                                        )
                                      }
                                    >
                                      RRP Price
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          buyPriceUpdateCheapest({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                          })
                                        )
                                      }
                                    >
                                      Buy Price
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          tradePriceUpdateCheapest({
                                            brand: selectedBrand,
                                            category: selectedCategory,
                                          })
                                        )
                                      }
                                    >
                                      Trade Price
                                    </DropdownItem>
                                  </li>
                                </DropdownMenu>
                              </UncontrolledDropdown>

                              <UncontrolledDropdown
                                className="dropdown d-inline-block me-1"
                                disabled={priceUpdateLoading}
                              >
                                <DropdownToggle
                                  type="menu"
                                  className="btn btn-light"
                                  id="dropdownMenuButton1"
                                >
                                  Update {isCheck.length > 0 ? "Selected" : null}{" "}
                                  Product
                                  {priceUpdateLoading ? (
                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                  ) : (
                                    <i className="mdi mdi-dots-vertical"></i>
                                  )}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <li>
                                    <DropdownItem
                                      onClick={() =>
                                        showConfirm(() =>
                                          priceUpdate({ brand: selectedBrand })
                                        )
                                      }
                                    >
                                      Alarm Expert
                                    </DropdownItem>
                                  </li>
                                  <li>
                                    <DropdownItem href="#">
                                      Surveillance Master
                                    </DropdownItem>
                                  </li>
                                  {/* <UncontrolledDropdown
                              className="dropdown"
                              disabled={priceUpdateLoading}
                              direction="end"
                            >
                              <DropdownToggle
                                type="menu"
                                className="btn btn-light text-nowrap w-100"
                                id="dropdownMenuButton1"
                              >
                                {isCheck.length > 0
                                  ? "Selected RRP Price"
                                  : selectedBrand || selectedCategory
                                  ? "RRP Price by Filter"
                                  : "All RRP Price"}{" "}
                                to{" "}
                                {priceUpdateLoading ? (
                                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                ) : null}
                              </DropdownToggle>
                              <DropdownMenu style={{ top: "0px !important" }}>
                                <li>
                                  <DropdownItem
                                    onClick={() =>
                                      priceUpdate({ brand: selectedBrand })
                                    }
                                  >
                                    Alarm Expert
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem href="#">
                                    Surveillance Master
                                  </DropdownItem>
                                </li>
                              </DropdownMenu>
                            </UncontrolledDropdown> */}

                                  {/* <UncontrolledDropdown
                              className="dropdown"
                              disabled={tradePriceUpdateLoading}
                              direction="end"
                            >
                              <DropdownToggle
                                type="menu"
                                className="btn btn-light text-nowrap w-100"
                                id="dropdownMenuButton1"
                              >
                                {isCheck.length > 0
                                  ? "Selected Trade Price"
                                  : selectedBrand || selectedCategory
                                  ? "Trade Price by Filter"
                                  : "All Trade Price"}{" "}
                                to{" "}
                                {tradePriceUpdateLoading ? (
                                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                ) : null}
                              </DropdownToggle>
                              <DropdownMenu style={{ top: "0px !important" }}>
                                <li>
                                  <DropdownItem
                                    onClick={() =>
                                      tradePriceUpdate({ brand: selectedBrand })
                                    }
                                  >
                                    Alarm Expert
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem href="#">
                                    Surveillance Master
                                  </DropdownItem>
                                </li>
                              </DropdownMenu>
                            </UncontrolledDropdown>

                            <UncontrolledDropdown
                              className="dropdown"
                              disabled={buyPriceUpdateLoading}
                              direction="end"
                            >
                              <DropdownToggle
                                type="menu"
                                className="btn btn-light text-nowrap w-100"
                                id="dropdownMenuButton1"
                              >
                                {isCheck.length > 0
                                  ? "Selected Buy Price"
                                  : selectedBrand || selectedCategory
                                  ? "Buy Price by Filter"
                                  : "All Buy Price"}{" "}
                                to{" "}
                                {buyPriceUpdateLoading ? (
                                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                                ) : null}
                              </DropdownToggle>
                              <DropdownMenu style={{ top: "0px !important" }}>
                                <li>
                                  <DropdownItem
                                    onClick={() =>
                                      buyPriceUpdate({ brand: selectedBrand })
                                    }
                                  >
                                    Alarm Expert
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem href="#">
                                    Surveillance Master
                                  </DropdownItem>
                                </li>
                              </DropdownMenu>
                            </UncontrolledDropdown> */}
                                </DropdownMenu>
                              </UncontrolledDropdown>

                              {/* <Button
                          className="btn btn-light me-1"
                          onClick={stockUpdate}
                          disabled={stockUpdateLoading}
                        >
                          Update Stock
                          {stockUpdateLoading ? (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2 ms-1"></i>
                          ) : (
                            <i className="bx bx-box ms-1"></i>
                          )}
                        </Button> */}
                            </div>
                          </div>
                        </div>

                      </Row>
                      <Row className="mb-2 mt-4">
                        <Col sm="2">
                          <div className="search-box mb-2 d-inline-block">
                            <div className="position-relative">
                              <input
                                placeholder="Search..."
                                className="form-control"
                                onInput={e => {
                                  setPage(1)
                                  setSearch(e.target.value)
                                }}
                                value={search}
                                type="text"
                              />
                              <i className="bx bx-search-alt search-icon" />
                            </div>
                          </div>
                        </Col>
                        <Col sm="1">
                          <div className="w-100">
                            <div className="position-relative">
                              <select
                                className="form-control text-center px-0"
                                onChange={e => setShow(e.target.value)}
                                value={show}
                              >
                                <option value="10">Show 10</option>
                                <option value="20">Show 20</option>
                                <option value="50">Show 50</option>
                                <option value="100">Show 100</option>
                              </select>
                            </div>
                          </div>
                        </Col>
                        <Col sm="9">
                          <Row>
                            <Col md={{ size: 3, offset: 3 }}>
                              <select
                                className="form-control"
                                onChange={e => setFilterType(e.target.value)}
                              >
                                <option value="">Choose Filter</option>
                                <option value="1">
                                  Buy Price {">="} RRP Price - Diamond
                                </option>
                                <option value="2">
                                  Trade Price {">="} RRP Price
                                </option>
                                <option value="3">Buy Price - Diamond = 0</option>
                                <option value="4">Product Kit</option>
                                <option value="5">Product Disabled</option>
                              </select>
                            </Col>
                            <Col md={{ size: 3 }}>
                              <ReactSelect
                                options={brands?.brands}
                                onChange={e => {
                                  setSelectedBrand(e?.value)
                                }}
                                placeholder="Select Brand"
                                isClearable
                              ></ReactSelect>
                            </Col>
                            <Col md={{ size: 3 }}>
                              <ReactSelect
                                options={categories?.categories}
                                onChange={e => {
                                  setSelectedCategory(e?.value)
                                }}
                                placeholder="Select Category"
                                isClearable
                              ></ReactSelect>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <FormGroup check inline>
                          <input
                            id="selectAll"
                            name="selectAll"
                            onClick={handleSelectAll}
                            checked={isCheckAll}
                            type="checkbox"
                          />{" "}
                          <Label check>Select All</Label>
                        </FormGroup>
                      </Row>
                      {isLoading ? (
                        <Spinner className="ms-2 spinner-loading" color="success" />
                      ) : (
                        <Row>
                          <Col sm="12">
                            {data && (
                              <PaginationProvider
                                pagination={paginationFactory(pageOptions)}
                                keyField="_id"
                                columns={ProductColumns()}
                                data={data.data}
                              >
                                {({ paginationProps, paginationTableProps }) => (
                                  <ToolkitProvider
                                    keyField="_id"
                                    data={data.data}
                                    columns={ProductColumns()}
                                    bootstrap4
                                  // search
                                  >
                                    {toolkitProps => (
                                      <React.Fragment>
                                        <Row>
                                          <Col xl="12">
                                            <div className="table-responsive">
                                              <BootstrapTable
                                                keyField="_id"
                                                responsive
                                                sorted={false}
                                                striped={false}
                                                defaultSorted={defaultSorted}
                                                selectRow={selectRow}
                                                classes={
                                                  "table align-middle table-nowrap table-check"
                                                }
                                                headerWrapperClasses={"table-light"}
                                                {...toolkitProps.baseProps}
                                                {...paginationTableProps}
                                              />
                                            </div>
                                          </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-30">
                                          <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                            <Pagination
                                              changePage={e => setPage(e.page)}
                                              data={data}
                                            />
                                          </Col>
                                        </Row>
                                      </React.Fragment>
                                    )}
                                  </ToolkitProvider>
                                )}
                              </PaginationProvider>
                            )}
                          </Col>
                        </Row>
                      )}
                    </TabPane>
                    <TabPane tabId="2">
                      <Row>
                        <Table responsive>
                          <thead>
                            <tr>
                              <th>
                                Date
                              </th>
                              <th>
                                Log
                              </th>
                              <th>
                                Action By
                              </th>
                              <th>
                                #
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              loading ? (
                                <Spinner
                                  className="m-5"
                                  color="primary"
                                />) : logs.length >= 1 && logs.map((v, i) => (
                                  <>
                                    <tr>
                                      <th scope="row">
                                        {moment(v.created_at).format('DD MMMM YYYY HH:mm')}
                                      </th>
                                      <td>
                                        {v.log}
                                      </td>
                                      <td>
                                        {v.action_by}
                                      </td>
                                      <td>
                                        <Button
                                          color="primary"
                                          onClick={() => {
                                            setItems(v.item_fails)
                                            toggleModal()
                                          }}
                                        >
                                          View
                                        </Button>
                                      </td>
                                    </tr>
                                  </>
                                ))
                            }
                          </tbody>
                        </Table>
                        <Pagination
                          length={total}
                          postsPerPage={perPage}
                          currentPage={pageLog}
                          handlePagination={handlePagination}
                        />
                      </Row>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {
        open && (
          <ViewErrorAutoPriceLog
            toggle={toggleModal}
            open={open}
            data={items}
          />
        )
      }
      {modal && (
        <CreateUpdate
          modal={modal}
          toggle={toggle}
          handleCreateModal={handleCreateModal}
          data={modalData}
          refresh={getData}
        />
      )}
      {/* <Show
          // key="SHOW_PRODUCT"
          // modal={openModal == "SHOW_PRODUCT"}
          // toggle={toggleShow}
          // handleCreateModal={handleShowModal}
          // data={modalData}
          refresh={getData}
        /> */}
      {modalUpdateBuyPrice && (
        <UpdateBuyPriceBySupplier
          modal={modalUpdateBuyPrice}
          toggle={toggleUpdateBuyPrice}
          handleCreateModal={handleUpdateBuyPriceModal}
          data={modalData}
          refresh={getData}
        />
      )}
      <DeleteConfirmation
        showDelete={showDelete}
        setShowDelete={() => setShowDelete(false)}
        deleteAction={deleteAction}
      />
    </React.Fragment>
  )
}

export default connect(
  state => ({
    modalDataset: state.modal?.data,
    openModal: state.modal?.modal,
  }),
  {
    setModalDataset,
    setOpenModal,
  }
)(React.memo(Product))