import { useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import moment from 'moment';
import React, { useMemo } from 'react'
import { Table } from 'reactstrap';

const Log = ({ data, updateField, refresh }) => {
  const { data: logs, isLoading } = useQuery(
    ["product-logs", data._id],
    () => api.productLogs({ params: { id: data._id } }),
    {
      refetchOnWindowFocus: false,
    }
  )

  return (
    <>
      <h4
        className="text-center mt-3 mb-3 text-white w-25 mx-auto p-2"
        style={{ backgroundColor: "#132747" }}
      >
        Log History
      </h4>

      {!isLoading ? (
        <Table className="table mb-5 table-bordered">
          <thead className="text-white" style={{ backgroundColor: "#132747" }}>
            <tr className="text-center">
              <th>Date</th>
              <th>Description</th>
              <th>By</th>
            </tr>
          </thead>
          <tbody>
            {logs.map(log => (
              <tr className="" key={log._id}>
                <th>{moment(log.created_at).format("DD MMM YYYY HH:mm")}</th>
                <th>
                  <ul>
                    {log.logs.map((item, i) => (
                      <li key={i}>{item}</li>
                    ))}
                  </ul>
                </th>
                <th>{log.user}</th>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : null}
    </>
  )
}

export default React.memo(Log);