import React from "react"
import { useModalWindow } from "react-modal-global"

function PopupModal(props) {
  const modal = useModalWindow()

  return (
    // <div className="popup-layout" style={{ "--popup-width": props.width }}>
    //   <div className="popup-layout__container">
    //     {modal?.params?.closable && (
    //       <button
    //         className="popup-layout__close"
    //         type="button"
    //         onClick={modal?.close}
    //       >
    //         &#9587;
    //       </button>
    //     )}
    //     <div className="popup-layout__inner">{props.children}</div>
    //   </div>
    // </div>
    <div className="modal-dialog modal-xl modal-dialog-centered">
      <div className="modal-content">{props.children}</div>
    </div>
  )
}

export default PopupModal