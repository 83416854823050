import React from 'react'
import { Pagination as Paginationreact, PaginationItem, PaginationLink } from 'reactstrap';

const Pagination = ({ postsPerPage, length, handlePagination, currentPage }) => {
    let paginationNumber = []
    for (let i = 1; i <= Math.ceil(length / postsPerPage); i++) {
        paginationNumber.push(i);
    }
    return (
        <Paginationreact>
            {
                paginationNumber.map((data) => (
                    <PaginationItem key={data} active={currentPage == data ? true : false}>
                        <PaginationLink onClick={() => handlePagination(data)} >
                            {data}
                        </PaginationLink>
                    </PaginationItem>
                ))
            }
        </Paginationreact>
    )
}
export default Pagination