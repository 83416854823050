import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Button, Table, Spinner, Badge, Nav, NavItem, NavLink, Row, TabPane, TabContent, CardHeader, CardTitle, CardText, Input } from 'reactstrap';
import Breadcrumbs from "components/Common/Breadcrumb"
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import moment from 'moment';
import DeleteConfirmation from 'components/Common/DeleteConfirmation';
import axios from 'axios';
import Pagination from 'components/Pagination';

const StockUpdate = () => {

  const [errors, setErrors] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingJob, setLoadingJob] = useState(false)
  const [loadingDelete, setLoadingDelete] = useState(false)
  const [showDelete, setShowDelete] = useState(false)
  const [supplierId, setSupplierId] = useState(false)
  const [activeTab, setActiveTab] = useState('1');
  const [logs, setLogs] = useState([])
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [total, setTotal] = useState(20)

  const [countTotalGetProductFromSupplier, setCountTotalGetProductFromSupplier] = useState(0)
  const [countTotalPushAeSuccess, setCountTotalPushAeSuccess] = useState(0)
  const [countTotalPushAeFailed, setCountTotalPushAeFailed] = useState(0)
  const [loadingSummary, setLoadingSummary] = useState(false)
  const [dateSummary, setDateSummary] = useState(null)

  const handlePagination = (pageNumber) => {
    setPage(pageNumber);
    getLogSupplierStockUpdate(pageNumber, perPage)
  };

  useEffect(() => {
    setLoading(true)
    api.getStockSupplierUpdate({ page, perPage }).then((res) => {
      if (res.status) {
        setData(res.data)
      }
      setLoading(false)
    }).catch((e) => {
      console.log(e)
      setLoading(false)
    })
    getLogSupplierStockUpdate()
    getSupplierUpdateSummaryPushAE()
  }, [])

  const getStockSupplierUpdate = async () => {
    try {
      const res = await api.getStockSupplierUpdate()
      if (res.status) {
        setData(res.data)
      }
    } catch (error) {
      setLoading(false)
    }
  }

  const getLogSupplierStockUpdate = async (page = 1, perPage = 10) => {

    api.getLogStopStockSupplierUpdate({ page, perPage }).then((res) => {
      if (res.status) {
        setLogs(res.data)
        setPage(res.page)
        setTotal(res.count)
        setPerPage(res.perPage)
      }
    }).catch((e) => {
      console.log(e)
    })
  }

  const runJob = async (id) => {
    try {
      setLoadingJob(true)
      const res = await api.JobRunStockSupplierUpdate({ id })
      setLoadingJob(false)
      if (!res.status) {
        showToast(res.message, 'error')
        return
      }
      showToast(res.message, 'success')
      getStockSupplierUpdate()
    } catch (error) {
      setLoadingJob(false)
      console.log(error)
    }
  }

  const stopJob = async (id) => {
    try {
      setLoadingJob(true)
      const res = await api.JobStopStockSupplierUpdate({ id })
      setLoadingJob(false)
      if (!res.status) {
        showToast(res.message, 'error')
        return
      }
      showToast(res.message, 'success')
      getStockSupplierUpdate()
    } catch (error) {
      setLoadingJob(false)

      console.log(error)
    }
  }

  const deleteSupplier = async () => {
    try {
      setLoadingDelete(true)
      const res = await api.deleteStockSupplierUpdate({ id: supplierId })
      setLoadingDelete(false)
      console.log(res)
      if (!res.status) {
        showToast(res.message, 'error')
        return
      }
      showToast(res.message, 'success')
      getStockSupplierUpdate()
      setSupplierId(null)
    } catch (error) {
      setLoadingDelete(false)
      if (axios.isAxiosError(error)) {
        showToast(error?.response?.data?.message, 'error')
        return
      }
      console.log(error)
    }
  }

  const getSupplierUpdateSummaryPushAE = async (supplierCode = 'ALLOYS') => {
    try {
      setLoadingSummary(true)
      const res = await api.getSummaryStockPushUpdateAE({ supplierCode })
      if (res.status) {
        setCountTotalGetProductFromSupplier(res.data.total_get_product_from_supplier)
        setCountTotalPushAeSuccess(res.data.total_update_success)
        setCountTotalPushAeFailed(res.data.total_update_fail)
        setDateSummary(moment(res.data.last_update).format('DD MMMM YYYY HH:mm'))
      }
      setLoadingSummary(false)
    } catch (error) {
      setLoadingSummary(false)
      setCountTotalGetProductFromSupplier(0)
      setCountTotalPushAeSuccess(0)
      setCountTotalPushAeFailed(0)
      setDateSummary(null)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Home" breadcrumbItem="Stock Update" />
          <Card>
            <CardBody>
              <div>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={activeTab == '1' ? 'active' : ''}
                      onClick={() => setActiveTab('1')}
                    >
                      Supplier
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab == '2' ? 'active' : ''}
                      onClick={() => setActiveTab('2')}
                    >
                      Logs
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab == '3' ? 'active' : ''}
                      onClick={() => setActiveTab('3')}
                    >
                      Summary Push AE
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              #
                            </th>
                            <th>
                              Name
                            </th>
                            <th>
                              Code
                            </th>
                            <th>
                              Job Is Active
                            </th>
                            <th>
                              Next Run Job
                            </th>
                            <th>
                              Latest run job at
                            </th>
                            <th>
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loading ? (
                              <Spinner
                                className="m-5"
                                color="primary"
                              />) : data.length >= 1 && data.map((v, i) => (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {i + 1}
                                    </th>
                                    <td>
                                      {v.name}
                                    </td>
                                    <td>
                                      {v.supplier_code}
                                    </td>
                                    <td>
                                      {v.job_is_active ? (<Badge color="success">
                                        Active
                                      </Badge>) : (<Badge color="danger">
                                        Stopped
                                      </Badge>)}
                                    </td>
                                    <td>
                                      {v.job_next_run_at ? moment(v.job_next_run_at).format('DD MMMM YYYY HH:mm') : '-'}
                                    </td>
                                    <td>
                                      {v.job_last_run_at ? moment(v.job_last_run_at).format('DD MMMM YYYY HH:mm') : '-'}
                                    </td>
                                    <td>
                                      <div>
                                        {/* <Button
                                  color="secondary"
                                  style={{ margin: 3 }}
                                >
                                  Update
                                </Button> */}
                                        <Button
                                          color={v.job_is_active ? "danger" : "success"}
                                          style={{ margin: 3 }}
                                          onClick={() => v.job_is_active ? stopJob(v._id) : runJob(v._id)}
                                        >
                                          {
                                            loadingJob ? <Spinner /> : v.job_is_active ? 'Stop Job' : 'Run Job'
                                          }
                                        </Button>
                                        {/* <Button
                                          color="danger"
                                          style={{ margin: 3 }}
                                          onClick={() => {
                                            setSupplierId(v._id)
                                            setShowDelete(true)
                                          }}
                                        >
                                          Delete
                                        </Button> */}
                                      </div>
                                    </td>
                                  </tr>
                                </>
                              ))
                          }
                        </tbody>
                      </Table>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Table responsive>
                        <thead>
                          <tr>
                            <th>
                              Date
                            </th>
                            <th>
                              Log
                            </th>
                            <th>
                              Supplier Name
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {
                            loading ? (
                              <Spinner
                                className="m-5"
                                color="primary"
                              />) : logs.length >= 1 && logs.map((v, i) => (
                                <>
                                  <tr>
                                    <th scope="row">
                                      {moment(v.created_at).format('DD MMMM YYYY HH:mm')}
                                    </th>
                                    <td>
                                      {v.log}
                                    </td>
                                    <td>
                                      {v.name}
                                    </td>
                                  </tr>
                                </>
                              ))
                          }
                        </tbody>
                      </Table>
                      <Pagination
                        length={total}
                        postsPerPage={perPage}
                        currentPage={page}
                        handlePagination={handlePagination}
                      />
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row xs="4" className="mt-3">
                      <Col>
                        <Input
                          className="mb-3"
                          type="select"
                          onChange={(e) => getSupplierUpdateSummaryPushAE(e.target.value)}
                        >
                          <option value="ALLOYS">
                            Alloys
                          </option>
                          <option value="LEADERSYSTEM">
                            Leader System
                          </option>
                        </Input>
                      </Col>
                    </Row>

                    <Row xs="4">
                      <Col>
                        <Card
                          className="my-2"
                          color="primary"
                          outline
                        // style={{
                        //   width: '18rem'
                        // }}
                        >
                          <CardHeader>
                            Total Get Product From Management AE
                          </CardHeader>
                          <CardBody>
                            <CardTitle tag="h2">
                              {loadingSummary ? <Spinner /> : countTotalGetProductFromSupplier}
                            </CardTitle>
                            <CardText className="mt-4">
                              Last Update: {loadingSummary ? <Spinner /> : dateSummary ? dateSummary : '-'}
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          className="my-2"
                          color="primary"
                          outline
                        // style={{
                        //   width: '18rem'
                        // }}
                        >
                          <CardHeader color="success">
                            Total Push Stock Update To AE Success
                          </CardHeader>
                          <CardBody>
                            <CardTitle tag="h2">
                              {loadingSummary ? <Spinner /> : countTotalPushAeSuccess}
                            </CardTitle>
                            <CardText className="mt-4">
                              Last Update: {loadingSummary ? <Spinner /> : dateSummary ? dateSummary : '-'}
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col>
                        <Card
                          className="my-2"
                          color="primary"
                          outline
                        // style={{
                        //   width: '18rem'
                        // }}
                        >
                          <CardHeader>
                            Total Push Stock Update To AE Failed
                          </CardHeader>
                          <CardBody>
                            <CardTitle tag="h2">
                              {loadingSummary ? <Spinner /> : countTotalPushAeFailed}
                            </CardTitle>
                            <CardText className='mt-4'>
                              Last Update: {loadingSummary ? <Spinner /> : dateSummary ? dateSummary : '-'}
                            </CardText>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>


                  </TabPane>
                </TabContent>
              </div>

            </CardBody>
          </Card>
        </Container >
      </div >
      <DeleteConfirmation showDelete={showDelete} setShowDelete={() => setShowDelete(false)} deleteAction={deleteSupplier} />
    </React.Fragment >

  )
}

export default React.memo(StockUpdate);