import { GET_MODAL_DATA, OPEN_MODAL, SET_MODAL_DATA } from "./actionTypes"

const INIT_STATE = {
  data: null,
  modal: null,
  error: {},
}

const modal = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_MODAL_DATA:
      return {
        ...state,
        data: action.payload,
      }
    case OPEN_MODAL:
      return {
        ...state,
        modal: action.payload,
      }
    case GET_MODAL_DATA:
      return {
        ...state,
      }

    default:
      return state
  }
}

export default modal
