import React, { useEffect, useMemo, useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'reactstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation, useQuery } from '@tanstack/react-query';
import api from 'helpers/api';
import { showToast } from 'helpers/utils';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import ReactSelect from 'react-select';
import moment from 'moment';

const CreateUpdate = ({ data, toggle, modal, handleCreateModal, refresh }) => {
  const [errors, setErrors] = useState([])
  const scrapePrice = useMemo(() => data?.scrape?.[data?.scrape.length - 1], [data])
  const scrapePriceStock = useMemo(() => {
    let scrape = data?.scrape?.[data?.scrape.length - 1]
    let urls = scrape?.scrape_urls?.filter(item => !!item.stock)
    let stock = [];
    urls?.map(item => {
      item.scrape_url_logs.map(log => {
        stock[moment(log.date).format('DD/MM/YYYY')] = log.stock;
      })
    })
    return stock;
  }, [data])
  const { register, setValue, handleSubmit, reset, control, formState: { formErrors } } = useForm({
    defaultValues: {
      product_id: scrapePrice?.product,
      limit_price: scrapePrice?.limit_price,
      scrape: scrapePrice?.scrape_urls.map(item => ({
        scrape_url_list: { label: item.scrape_url_list.code, value: item.scrape_url_list._id },
        code: item.scrape_url_list.code,
        url: item.url,
      }))
    }
  });

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "scrape",
  });

  const { data: suppliers, refetch: getData, isLoading } = useQuery(
    ['price-suppliers'],
    () => api.scrapePriceSupplier(),
    {
      refetchOnWindowFocus: true,
    });

  const { mutate, isLoading: submitLoading } = useMutation(async (params) => {
    return (await data) ? api.updateScrapePrice(data._id, params) : api.createUser(params);
  }, {
    onSuccess: (res) => {
      showToast(res.message, !res.status && 'error')
      if (!res.status) {
        setErrors(res.errors)
        return;
      }
      toggle()
      refresh()
    },
    onError: (err) => {
      showToast('Failed to submit data', 'error')
    }
  })

  useEffect(() => {
    setErrors([]);
    reset({
      ...data,
      product_id: scrapePrice?.product,
      limit_price: scrapePrice?.limit_price,
      scrape: scrapePrice?.scrape_urls.map(item => ({
        scrape: item.scrape,
        scrape_url_list: { label: item.scrape_url_list.code, value: item.scrape_url_list._id },
        url: item.url,
      }))
    });
  }, [data, modal])

  // const scrapeLogs = useMemo(() => {
  //   let scrape = data?.scrape_trade?.[row.scrape.length - 1]
  //   let logs = [];
  //   return scrape.scrape_urls[0].scrape_url_logs.map(item => item)
  // }, [data])

  // console.log(scrapeLogs)

  return (
    <Modal
      size="xl"
      toggle={() => handleCreateModal()}
      isOpen={modal}
      centered
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0 text-center w-100">
          Scrape RRP Price
        </h5>
        <button
          onClick={() => handleCreateModal()}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-between">
          <div>
            <p><b>SKU : {data.sku}</b></p>
            <p><b>Product : {data.name}</b></p>
            <p>
              <b>Supplier : {[...new Map(data.suppliers.map(item => [item, item])).values()].map(item => item).join(", ")}</b>
            </p>
            <div className="mb-3">
              <label>Limit Price (Inc)</label>
              <input
                type="text"
                className="form-control w-50"
                placeholder="Limit Price"
                {...register('limit_price')}
                defaultValue={scrapePrice?.limit_price}
                required
              />
            </div>
          </div>
          <div>
            <p><b>Last Update : {moment(scrapePrice?.updated_at).format('DD MMM YYYY hh:mm')}</b></p>
            <p><b>Person : XYZ</b></p>
          </div>
        </div>

        <h4 className="text-center mb-3 text-white" hover style={{ backgroundColor: '#132747' }}>Price</h4>
        <Table className="table mb-5">
          <thead className="text-white" style={{ backgroundColor: '#132747' }}>
            <tr className="text-center">
              <th>Buy Price</th>
              <th>RRP Price</th>
              <th>Trade Price (Trade)</th>
              <th>Silver</th>
              <th>Gold</th>
              <th>Platinum</th>
              <th>Diamond</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center">
              <th>Exc: ${Number(data.buy_price / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price_1 / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price_2 / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price_3 / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price_4 / 1.1).toFixed(2)}</th>
              <th>Exc: ${Number(data.price_5 / 1.1).toFixed(2)}</th>
            </tr>
            <tr className="text-center">
              <th>Inc: ${Number(data.buy_price).toFixed(2)}</th>
              <th>Inc: ${Number(data.price).toFixed(2)}</th>
              <th>Inc: ${Number(data.price_1).toFixed(2)}</th>
              <th>Inc: ${Number(data.price_2).toFixed(2)}</th>
              <th>Inc: ${Number(data.price_3).toFixed(2)}</th>
              <th>Inc: ${Number(data.price_4).toFixed(2)}</th>
              <th>Inc: ${Number(data.price_5).toFixed(2)}</th>
            </tr>
          </tbody>
        </Table>

        <h4 className="text-center mb-3 text-white" style={{ backgroundColor: '#132747' }}>Price History</h4>

        <Table className="table mb-5">
          <thead className="text-white" style={{ backgroundColor: '#132747' }}>
            <tr className="text-center">
              <th>Date</th>
              {scrapePrice?.scrape_urls?.map(scrapeUrl => (
                <th key={scrapeUrl._id}>{scrapeUrl.scrape_url_list.name}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(scrapePrice?.scrape_urls?.[0]?.scrape_url_logs?.length).keys()].map(log => (
              <tr className="text-center" key={log._id}>
                <th>{moment(scrapePrice?.scrape_urls?.[0]?.scrape_url_logs?.[log]?.date).format('DD/MM/YYYY')}</th>
                {[...Array(scrapePrice?.scrape_urls?.length).keys()].map(i => (
                  <th key={i}>{scrapePrice?.scrape_urls?.[i]?.scrape_url_logs?.[log]?.price ? `$${scrapePrice?.scrape_urls?.[i]?.scrape_url_logs?.[log]?.price}` : '-'}</th>
                ))}
              </tr>
            ))}
          </tbody>
          {/* {scrapePrice?.scrape_urls?.map(scrapeUrl => (
          ))} */}
        </Table>

        <table className="table table-bordered py-1">
          {fields.map((scrape, index) => (
            <tr key={scrape.id}>
              <th>Supplier</th>
              <th>
                <Controller
                  control={control}
                  // defaultValue={scrape.code}
                  name={`scrape.${index}.scrape_url_list`}
                  render={({ onChange, value, name, ref, field }) => (
                    <ReactSelect
                      inputRef={ref}
                      isLoading={isLoading}
                      options={suppliers?.suppliers.filter(item => !data?.scrape_price_supplier?.includes(item.value))}
                      value={suppliers?.suppliers.find(c => c.value === value)}
                      onChange={val => onChange(val.value)}
                      isClearable
                      defaultValue={[{ label: scrape.code, value: scrape.scrape_url_list, }]}
                      {...field}
                    >
                    </ReactSelect>
                  )}
                />
              </th>
              <th>Link</th>
              <th width={`50%`}>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Link"
                  defaultValue={scrape.url}
                  {...register(`scrape.${index}.url`)}
                />
              </th>
              <td width={50}>
                <button className="btn btn-danger bg-danger" onClick={() => remove(index)}>
                  <i className="bx bx-trash"></i>
                </button>
              </td>
            </tr>
          ))}
        </table>

        <button className="btn btn-primary btn-navy" onClick={() => append()}>Add Link</button>


        <form onSubmit={handleSubmit(mutate)}>
          {/* <Row>
            <Col>
              <div className="mb-3">
                <label>Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name"
                  {...register('name', { required: true })}
                />
                {formErrors?.name && <small className="text-danger">This field is required</small>}
                {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Email</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Email"
                  {...register('email', { required: true })}
                />
                {formErrors?.email && <small className="text-danger">This field is required</small>}
                {errors.email && <span className="form-text text-danger">{errors.email[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Password</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password"
                  {...register('password', { required: data ? false : true })}
                />
                {formErrors?.password && <small className="text-danger">This field is required</small>}
                {errors.password && <span className="form-text text-danger">{errors.password[0]}</span>}
              </div>
            </Col>
            <Col>
              <div className="mb-3">
                <label>Password Confirmation</label>
                <input
                  type="password"
                  className="form-control"
                  placeholder="Password Confirmation"
                  {...register('password_confirmation', { required: data ? false : true })}
                />
                {formErrors?.password_confirmation && <small className="text-danger">This field is required</small>}
                {errors.password_confirmation && <span className="form-text text-danger">{errors.password_confirmation[0]}</span>}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="mb-3">
                <label>Role</label>
                <ReactSelect
                  options={[
                    {
                      value: 'Admin',
                      label: 'Admin',
                    },
                    {
                      value: 'Manager',
                      label: 'Manager',
                    },
                    {
                      value: 'Blogger',
                      label: 'Blogger',
                    },
                  ]}
                  name="category"
                  onChange={(e) => {
                    setValue('role', e?.value)
                  }}
                  defaultValue={[{
                    value: data?.role,
                    label: data?.role,
                  }]}
                  isClearable
                >
                </ReactSelect>
                {formErrors?.name && <small className="text-danger">This field is required</small>}
                {errors.name && <span className="form-text text-danger">{errors.name[0]}</span>}
              </div>
            </Col>
          </Row> */}

        </form>
      </div>
      <div className="modal-footer">
        <div className="d-md-flex justify-content-md-end">
          <button type="submit" className="btn btn-primary btn-navy w-md" disabled={submitLoading} onClick={handleSubmit(mutate)}>
            {submitLoading ? (
              <>
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle me-2"></i>
                Loading
              </>
            ) : (
              <span>Submit</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default React.memo(CreateUpdate);