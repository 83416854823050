import React, { useEffect, useState } from 'react'
import { Col, Modal, Row, Table } from 'reactstrap';

const ViewErrorAutoPriceLog = ({ data = [], toggle, open }) => {

    return (
        <Modal
            size="xl"
            toggle={toggle}
            isOpen={open}
            centered
            zIndex={9999}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0">
                    {"Items failed to update"}
                </h5>
                <button
                    onClick={toggle}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <Table responsive>
                    <thead>
                        <tr>
                            <th>
                                No
                            </th>
                            <th>
                                Product Name
                            </th>
                            <th>
                                SKU
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.length >= 1 ? data.map((v, i) => (
                                <>
                                    <tr>
                                        <th scope="row">
                                            {i + 1}
                                        </th>
                                        <th scope="row">
                                            {v.product_name}
                                        </th>
                                        <td>
                                            {v.sku}
                                        </td>
                                        <td>
                                            {v.status}
                                        </td>
                                    </tr>
                                </>
                            )) : <tr>
                                <td colSpan={3} >
                                    Empty
                                </td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
            <div className="modal-footer">
                <div className="d-md-flex justify-content-md-end">

                </div>
            </div>
        </Modal>
    )
}

export default React.memo(ViewErrorAutoPriceLog);