import React, { lazy } from "react"
import { ModalController } from "react-modal-global"
import PopupModal from "./components/Common/PopupModal"
import Show from './pages/Product/Action/Show';

const Modal = new ModalController({
  defaultParams: {},
  components: {
    Show,
    // PopupModal: lazy(() => import("./components/Common/PopupModal")),
  },
})

export default Modal
